<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a
                                    href="#"
                                    @click="$router.push('/manage/accounts')"
                                    >Akun</a
                                >
                            </li>
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                Buat Akun
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <!-- <button-back /> -->
                    <ButtonBack />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">
                                Buat Akun
                            </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <form
                                @submit.prevent="
                                    selected.role == 1
                                        ? createAccountStudent()
                                        : createAccountTeacher()
                                "
                            >
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Tipe Akun</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="roleSelect">Role</label>
                                            <select
                                                v-model="selected.role"
                                                @change="onChangeRole($event)"
                                                class="custom-select"
                                                id="roleSelect"
                                                required
                                            >
                                                <option selected value="0">
                                                    Pilih Role
                                                </option>
                                                <option
                                                    v-for="role in options.roles"
                                                    :value="role.value"
                                                    :key="role.value"
                                                >
                                                    {{ role.text }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-5" v-if="show == 1">
                                        <div class="form-group">
                                            <label for="classSelect"
                                                >Kelas</label
                                            >
                                            <select
                                                v-model="form.group_id"
                                                @change.prevent="
                                                    onChangeClassStudent($event)
                                                "
                                                class="custom-select"
                                                id="classSelect"
                                            >
                                                <option selected value="0">
                                                    Pilih Kelas
                                                </option>
                                                <option
                                                    v-for="clas in options.classes"
                                                    :value="clas.id"
                                                    :key="clas.str_id"
                                                    :code="clas.prefix_code"
                                                >
                                                    {{ clas.group_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-5" v-if="show == 2">
                                        <div class="form-group">
                                            <label for="courseSelect"
                                                >Mata Pelajaran</label
                                            >
                                            <select
                                                @change.prevent="
                                                    onChangeClassTeacher($event)
                                                "
                                                v-model="form.subject_id"
                                                class="custom-select"
                                                id="courseSelect"
                                            >
                                                <option selected value="0">
                                                    Pilih Mata Pelajaran
                                                </option>
                                                <option
                                                    v-for="course in options.subjects"
                                                    :value="course.id"
                                                    :key="course.id"
                                                >
                                                    {{ course.subjects_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="show == 1">
                                    <div class="col-md-12">
                                        <h4>Profil</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="name">Nama Siswa</label>
                                            <input
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="show == 2">
                                    <div class="col-md-12">
                                        <h4>Profil</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="name"
                                                >Nama Pengajar</label
                                            >
                                            <input
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="show == 1">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">ID Siswa</label>
                                            <input
                                                v-model="form.str_id"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="password"
                                                >Password</label
                                            >
                                            <input
                                                v-model="form.password"
                                                type="password"
                                                class="form-control"
                                                id="password"
                                                required
                                                autocomplete="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="show == 2">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">ID Pengajar</label>
                                            <input
                                                v-model="form.str_id"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="password"
                                                >Password</label
                                            >
                                            <input
                                                v-model="form.password"
                                                type="password"
                                                class="form-control"
                                                id="password"
                                                required
                                                autocomplete="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="show == 1">
                                    <div class="col-md-12">
                                        <h4>Rentang Waktu Belajar</h4>
                                    </div>
                                </div>
                                <div class="row" v-if="show == 1">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="date-start"
                                                >Tanggal Masuk</label
                                            >
                                            <input
                                                v-model="form.start_date"
                                                type="date"
                                                class="form-control"
                                                id="date-start"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="date-end"
                                                >Tanggal Selesai</label
                                            >
                                            <input
                                                v-model="form.end_date"
                                                type="date"
                                                class="form-control"
                                                id="date-end"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="show == 1">
                                    <div class="col-md-12">
                                        <h4>Rincian Pembelajaran</h4>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="show == 1">
                                    <div class="col-md-5">
                                        <label for="date-end"
                                            >Mata Pelajaran</label
                                        >
                                        <div
                                            v-for="option in options.subjects_list"
                                            :key="option"
                                            class="row"
                                        >
                                            <div class="col mb-1">
                                                <b-form-input
                                                    :value="option"
                                                    readonly
                                                ></b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="show == 1">
                                    <div class="col-md-5">
                                        <label for="total_quiz"
                                            >Jumlah Kuis</label
                                        >
                                        <b-form-input
                                            id="total_quiz"
                                            v-model="selected.total_quiz"
                                            value="0/48"
                                            readonly
                                        ></b-form-input>
                                    </div>
                                    <div class="col-md-5">
                                        <label for="total_tryout"
                                            >Jumlah Try Out</label
                                        >
                                        <b-form-input
                                            id="total_tryout"
                                            v-model="selected.total_tryout"
                                            value="0/48"
                                            readonly
                                        ></b-form-input>
                                    </div>
                                </div>
                                <div
                                    class="row mt-5 px-5"
                                    v-if="selected.role != 0"
                                >
                                    <b-col class="text-center">
                                        <b-button
                                            variant="outline-secondary"
                                            size="lg"
                                            class="px-5"
                                            @click="$router.go(-1)"
                                            >Batal</b-button
                                        >
                                        <b-button
                                            variant="primary"
                                            size="lg"
                                            class="px-5"
                                            type="submit"
                                            >Simpan</b-button
                                        >
                                    </b-col>
                                </div>
                            </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import ButtonBack from "../../build-in/ButtonBack";

export default {
    components: {
        ButtonBack,
    },
    data() {
        return {
            form: new Form({
                id: "",
                subject_id: 0,
                str_id: "",
                group_id: 0,
                name: "",
                password: "",
                start_date: "",
                end_date: "",
            }),
            selected: {
                id: 0,
                role: 0,
                class: 0,
                courses: [],
                total_quiz: "",
                total_tryout: "",
            },
            nextID: 0,
            show: "",
            options: {
                roles: [
                    { id: 1, text: "Siswa", value: 1 },
                    { id: 2, text: "Pengajar", value: 2 },
                ],
                classes: [],
                subjects: [],
                subjects_list: [],
                subjects_list_id: [],
            },
        };
    },
    methods: {
        onChangeRole(event) {
            this.show = event.target.value;
            this.form.str_id = "";
            this.form.name = "";
            this.form.password = "";
            this.form.start_date = "";
            this.form.end_date = "";
            if (this.show == 1) {
                this.loadGroups();
            } else if (this.show == 2) {
                this.loadSubjects(this.show);
            }
        },
        async onChangeClassStudent(event) {
            let options = event.target.options;
            if (options.selectedIndex > -1) {
                let prefix =
                    options[options.selectedIndex].getAttribute("code");
                let id = options[options.selectedIndex].getAttribute("value");
                await this.getNextIDClass(id);
                this.form.str_id = prefix + this.nextID;

                await this.loadGroupSubjects(id);
                await this.loadTotalTryOutByGroup(id);
            }
        },
        async onChangeClassTeacher(event) {
            let options = event.target.options;
            if (options.selectedIndex > -1) {
                let prefix = "TCH";
                await this.getNextIDSubject();
                this.form.str_id = prefix + this.nextID;
            }
        },
        createAccountStudent() {
            this.$Progress.start();
            this.form
                .post("/api/students")
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/accounts`,
                            query: { type: `student` },
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                });
        },
        createAccountTeacher() {
            this.$Progress.start();
            this.form
                .post("/api/teachers")
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/accounts`,
                            query: { type: `teacher` },
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                });
        },
        async getNextIDClass(id) {
            await axios
                .get(`/api/group/total-students/${id}`)
                .then(({ data }) => {
                    let datas = data.data + 1;
                    let panjang = String(data.data).length;
                    let max = 4;
                    let result = 0;

                    if (panjang == max) {
                        this.nextID = datas;
                    } else {
                        for (let i = 0; i < max - panjang; i++) {
                            result += "0";
                        }
                        result += datas;
                        this.nextID = result;
                    }
                });
        },
        async getNextIDSubject() {
            await axios.get(`/api/account/teachers/total`).then(({ data }) => {
                let datas = data.data + 1;
                let panjang = String(data.data).length;
                let max = 4;
                let result = 0;

                if (panjang == max) {
                    this.nextID = datas;
                } else {
                    for (let i = 0; i < max - panjang; i++) {
                        result += "0";
                    }
                    result += datas;
                    this.nextID = result;
                }
            });
        },
        async loadGroupSubjects(id) {
            axios.get(`/api/group/subject/${id}`).then(({ data }) => {
                this.options.subjects_list = data.data[0];
                this.options.subjects_list_id = data.data[1];
                this.loadTotalQuizByGroup();
            });
        },
        async loadTotalTryOutByGroup(id) {
            axios
                .get(`/api/tryout/total/${id}`)
                .then(
                    ({ data }) =>
                        (this.selected.total_tryout = "0/" + data.data)
                );
        },
        loadTotalQuizByGroup() {
            axios
                .post(`/api/quiz/totalBySubject`, {
                    data: this.options.subjects_list_id,
                })
                .then(({ data }) => {
                    this.selected.total_quiz = "0/" + data.data;
                });
        },
        loadGroups() {
            axios
                .get("/api/group/list")
                .then(({ data }) => (this.options.classes = data.data));
        },
        loadSubjects(id) {
            axios
                .get("/api/subject/list")
                .then(({ data }) => (this.options.subjects = data.data));
        },
    },
};
</script>
