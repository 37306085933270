<template>
  <section class="content w-100">
    <div class="container">
      <div class="row mb-2">
        <div class="col-12">
            <h6><b>Soal {{quiz.title}}</b></h6>
        </div>
        <div class="col-12 text-center" style="font-size:12px;">
          <span v-on:click="setFlag()" style="font-size:14px; cursor:pointer;"><i class="fas fa-flag mr-2"></i>Tandai Soal Ini.</span>
        </div>
      </div>
      <div class="row">

        <div class="col-8">
          <div class="soal mb-2">
           <span class="number">{{quiz.currentNumber+1}}. </span> <span class="content" v-html="quizQuest.soal"></span>
          </div>
          <div class="answers form-check rounded" style="display:inline-block;"
          v-for="data, idx in quizQuest.opsi" :key="data.id">
            <input class="form-check-input" type="radio" v-bind:value="data.id" v-model="jawaban[questionID[quiz.currentNumber].id_soal]" name="jawaban" @click="setJawaban(data.id)">
            <p>{{letter[idx]}}. {{data.keterangan_opsi}}</p>
            <p v-if="data.gambar_opsi != ''">
              <img :src="host+data.gambar_opsi" style="max-width:100%;">
            </p>
          </div>
          <div class="text-right mt-3" style="max-width:90%;">
            <p v-if="quiz.currentNumber < quiz.totalQuest"><a href="javascript:void(0)" @click="goToNextNumber(quiz.currentNumber+1)">Selanjutnya</a></p>
          </div>
        </div>
        <div class="col-4">
          <div class="question-navigation">

            <div class="question-box border-bottom">
              <span class="float-left">
                <b>Waktu</b>
              </span>
              <span class="float-right">
                <Countdown :date="end" @onFinish="finish()" v-if="end != 0"></Countdown>
              </span>
            </div>

            <div class="question-box border-bottom">
              <span class="float-left">
                <b>Soal</b>
              </span>
            </div>
            <div class="question-boxes p-3" v-for="quizData, index in questionID" :key="index">
              <div
              class="box border text-center"
              @click="goToNextNumber(index)"
              v-bind:class="[quiz.flags[index] != '' ? quiz.flags[index] : '']"
              >
                  {{index+1}}
              </div>
            </div>
            <div class="finish-button text-center">
              <button class="btn btn-md btn-primary" @click="finish" :disabled="disabled">
                Selesai
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
  .soal span{
    display: inline;
  }
  .soal span :nth-last-child(1){
    display: inline;
  }
  .soal img{
    max-width:90%;
  }
</style>
<style scoped>
  .answers{
    background-color: white;
    margin-left:20px;
    max-width:90%;
    min-width:90%;
    padding:20px 20px 5px 35px;
    margin-bottom:13px;
  }
  .finish-button{
    margin-top:40px;
  }
  .question-boxes{
    display:inline-block;
    margin-right: -20px;
    margin-bottom: -30px;
  }
  .box{
    padding:3px;
    min-height:30px;
    min-width:28px;
    width:28px;
    height:30px;
    font-size:14px;
    cursor:pointer;
  }
  .question-box{
    min-height:50px;
    padding:12px;
  }
  .question-navigation{
    background-color: white;
    min-height:500px;
    margin-bottom:100px;
  }
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import Countdown from '../countdown/Countdown';

    export default {
      components: {
        Countdown
      },
        data () {
            return {
              host: '',
              disabled: false,
              end: 0,
              jawaban: {},
              subject_id: null,
              soal_id: null,
              questionID:[],
              flagNotAnswer: {},
              quizQuest:{},
              letter:['A','B','C','D','E'],
              quiz : {
                title: '',
                soal: '',
                choices: [],
                totalQuest: 0,
                currentNumber: 0,
                flags: {},
              },
              quizDetail:{},
            }
        },
        methods: {
          setJawaban(jawaban){
            this.jawaban[this.questionID[this.quiz.currentNumber].id_soal] = jawaban
            Vue.set(this.quiz.flags, this.quiz.currentNumber, 'bg-primary border-primary');
          },
          goToNextNumber(nextNumber){


              this.quiz.choices = []
              this.quiz.soal = ''

             axios.get("/api/siswa/quiz-question/"+this.questionID[nextNumber].id_soal)
                    .then(({ data }) => (this.quizQuest = data.data));
            this.quiz.currentNumber = nextNumber
            this.reloadBtn()
            if (this.questionID[nextNumber].id_soal in this.jawaban) {
                Vue.set(this.quiz.flags, nextNumber, 'bg-primary border-primary');
            } else if (this.questionID[nextNumber].id_soal in this.flagNotAnswer) {
                Vue.set(this.quiz.flags, nextNumber, 'bg-danger border-primary');
            } else {
                Vue.set(this.quiz.flags, nextNumber, 'border-primary');
            }
          },
          finish(){
            this.disabled = true
             axios.post('/api/siswa/quiz-submit', {
                  id_quiz: this.quizDetail.id,
                  subject_id: this.quizDetail.id_mapel,
                  answer: this.jawaban,
                }).then(({data}) => {
                      window.location = '/siswa/quiz/detail/'+data.data.id

                });
          },
          loadAllQuestionID(){
            axios.get("/api/siswa/quiz-question-id/"+this.$route.params.quiz_id)
                    .then(({ data }) => {
                        this.questionID = data.data
                        axios.get("/api/siswa/quiz-question/"+data.data[0].id_soal)
                        .then(({ data }) => (this.quizQuest = data.data));
            });

          },
          initialData(){
                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }
                this.host = loc+'/storage/images/'

                this.loadAllQuestionID()

                axios.get("/api/siswa/quiz-detail/"+this.$route.params.quiz_id).then(({data}) => {
                   this.quiz.choices = []
                     //show quiz data
                     let date = new Date()
                     let endDate = new Date(date.getTime() + data.data.waktu_pengerjaan*60000)
                     console.log(endDate)
                      this.quiz.title = data.data.quiz_title
                      this.quiz.totalQuest = data.data.soal_count
                      this.end = endDate
                      this.quizDetail = data.data
                });
                    Vue.set(this.quiz.flags, this.quiz.currentNumber, 'border-primary');
          },
          setFlag(){
              let index = this.quiz.currentNumber;
              this.flagNotAnswer[this.questionID[index].id_soal] = true
              if (!(this.questionID[index].id_soal in this.jawaban)) {
                  Vue.set(this.quiz.flags, index, 'bg-danger border-primary');
              }

          },
          reloadBtn(){
            for (let index = 0; index < this.questionID.length; index++) {
                if (this.questionID[index].id_soal in this.jawaban) {
                    Vue.set(this.quiz.flags, index, 'bg-primary');
                } else if (this.questionID[index].id_soal in this.flagNotAnswer) {
                    Vue.set(this.quiz.flags, index, 'bg-danger');
                } else {
                    Vue.set(this.quiz.flags, index, '');
                }
            }
          },
        },
        mounted() {

        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        },
    }
</script>
