<template>
  <section class="content w-100">
    <div class="container">
      <div class="row mb-3">
        <div class="col-6">
          <h4><b>{{title}}</b></h4>
        </div>
        <div class="col-6">
         <b>Nilai: {{score}}/{{maxScore}}</b>
        </div>
      </div>
      <div class="row" v-for="subject in tryoutAllQuest" :key="subject.id">

        <div class="col-12"><h5><b>{{subject.title}}</b></h5></div>   

        <div class="col-8" v-for="(quest, index) in subject.quests" :key="quest.id">

          <div class="soal mb-2">
           <span class="number">{{index+1}}. </span> <span class="content" v-html="quest.soal"></span>
          </div>

          <div v-for="option in quest.option" :key="option.id">
            <div class="answers form-check rounded"  style="display:inline-block;" :class="[option.selected == true ? 'border border-success' : '']">
              <input class="form-check-input" type="radio" :value="'{{option.option_name}}'" 
              :name="'jawaban'+quest.id" 
              :checked="option.selected" 
              disabled
              >
              <p>{{option.option_name}}. {{option.option_content}}</p>
              <p v-if="option.option_image != ''">
                <img :src="host+option.option_image" style="max-width:100%;">
              </p>
            </div>

            <span v-if="quest.type == 'jawaban_benar' && option.correct == true" >
              <i class="fa fa-check text-success" aria-hidden="true" style="font-size:18px;"></i>
            </span>
            <span v-if="quest.type == 'jawaban_benar' && option.selected == true && option.correct == false">
              <i class="fa fa-times text-danger" style="font-size:18px;"></i>
            </span>
            <span v-if="quest.type == 'point' ">
              {{option.option_point}}
            </span>

          </div>
          <div class="mt-4 mb-4">
            <p><b>Kunci Jawaban: {{quest.kunci_jawaban}}</b></p>
            <p><b>Pembahasan</b></p>
            <p class="pembahasan-img" v-html="quest.pembahasan"></p>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 text-center mb-4 mt-4">
            <a :href="'/siswa/try-out/detail/'+this.$route.params.id" class="btn btn-md btn-primary">Kembali ke Keterangan</a>
          </div>
      </div>
    </div>
  </section>
</template>
<style>
  .soal span{
    display: inline;
  }
  .soal span :nth-last-child(1){
    display: inline;
  }
  .soal img{
    max-width:90%;
  }
  .pembahasan-img img{
    max-width:90%;
  }
</style>
<style scoped>
  .answers{
    background-color: white;
    margin-left:20px;
    max-width:85%;
    min-width:85%;
    padding:20px 20px 5px 35px;
    margin-bottom:13px;
  }
  .finish-button{
    margin-top:40px;
  }
  .question-boxes{
    display:inline-block;
    margin-right: -20px;
    margin-bottom: -30px;
  }
  .box{
    padding:3px;
    min-height:30px;
    min-width:28px;
    width:28px;
    height:30px;
    font-size:14px;
    cursor:pointer;
  }
  .question-box{
    min-height:50px;
    padding:12px;
  }
  .question-navigation{
    background-color: white;
    min-height:500px;
    margin-bottom:100px;
  }
</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              host: '',
              title: '',
              score: 0,
              maxScore: 0,
              tryoutAllQuest: [],
            }
        },
        methods: {
          initialData(){
                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }
                this.host = loc+'/storage/images/'

                axios.get('/api/siswa/tryout/pembahasan/'+this.$route.params.id).then(({data}) => {
                  let tryoutAllQuest = [];

                  if(data.status){
                    this.title = data.title
                    this.score = data.score
                    this.maxScore = data.maxScore

                    for(let i=0; i<data.quest.length; i++){
                      let soal = [];
                      let kunci_jawaban = '';
                      for(let j=0; j<data.quest[i].soal.length; j++){
                        let opsi = [];

                        for(let k=0; k<data.quest[i].soal[j].soal.opsi.length; k++){
                          let objOpsi = {
                            id: data.quest[i].soal[j].soal.opsi[k].id,
                            option_name: data.quest[i].soal[j].soal.opsi[k].opsi_name,
                            option_content: data.quest[i].soal[j].soal.opsi[k].keterangan_opsi,
                            option_image: data.quest[i].soal[j].soal.opsi[k].gambar_opsi,
                            option_point: data.quest[i].soal[j].soal.opsi[k].point,
                            selected: false,
                            correct: false,
                          };

                          if(data.quest[i].soal[j].soal.kunci_jawaban == data.quest[i].soal[j].soal.opsi[k].id){
                            objOpsi.correct = true
                            kunci_jawaban = data.quest[i].soal[j].soal.opsi[k].opsi_name
                          }

                          if(data.student_answer[data.quest[i].soal[j].soal.id] == data.quest[i].soal[j].soal.opsi[k].id){
                            objOpsi.selected = true
                          }
                          opsi.push(objOpsi);
                        }

                        soal.push({
                          id: data.quest[i].soal[j].soal.id,
                          soal: data.quest[i].soal[j].soal.soal,
                          type: data.quest[i].type,
                          kunci_jawaban: kunci_jawaban,
                          pembahasan: data.quest[i].soal[j].soal.pembahasan,
                          option: opsi,
                        })
                      }
                      tryoutAllQuest.push({
                        title: data.quest[i].nama_mapel,
                        quests: soal,
                      })
                    }

                    this.tryoutAllQuest = tryoutAllQuest
                  }else{
                    window.location = '/siswa/try-out/pre-exam/'+this.$route.params.id
                  }

                })
          }

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
