<template>
  <section class="content w-75">
    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header header-color text-white">

                <div class="float-left mt-2">
                    <h3 class="card-title font-weight-bold pt-2">Bank Soal</h3>
                </div>

                <div class="float-right mt-1 mr-2">
                    <a href="/create-question" class="btn btn-primary"><i class="fa fa-plus-circle" aria-hidden="true"></i> Buat Soal</a>
                 </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <div class="form-group has-search float-left mt-5 ml-3 mb-3" style="width:250px">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Mapel.." @keyup="loadCourse(1)" v-model="q">
                </div>


                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID Mapel</th>
                      <th>Mata Pelajaran (Mapel)</th>
                      <th>Jumlah Soal</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="course in courses.data" :key="course.str_id" v-on:click="detail(course.id)" style="cursor:pointer;">
                          <td>{{course.str_id}}</td>
                          <td>{{course.subjects_name.substring(0,60)}}</td>
                          <td>{{course.soal_count}}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                   <pagination :data="courses" @pagination-change-page="loadCourse">
                       <span slot="prev-nav">Sebelumnya</span>
                       <span slot="next-nav">Selanjutnya </span>
                   </pagination>
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>

<script>
    import moment from 'moment';
    export default {
        data () {
            return {
              courses: {},
              q: '',
              items: [
                {
                    text: 'Soal',
                    active: true
                }
              ],
            }
        },
        methods: {

          loadCourse(page=1){
              this.$Progress.start();

              if(this.q){
                axios.get('/api/question-courses?page=' + page+'&q='+this.q).then(({data}) => (this.courses = data.data));
              }else{
                axios.get('/api/question-courses?page=' + page).then(({data}) => (this.courses = data.data));
              }

              this.$Progress.finish();
          },
          formatDateUtc(value){
            if (value) {
                return moment(String(value)).format('DD MMMM YYYY, HH:mm:ss')
            }

          },
          detail(id){
            window.location.href = "/questions/"+id
          },

        },
        mounted() {

        },
        created() {
            this.$Progress.start();
            this.loadCourse();
            this.$Progress.finish();
        }
    }
</script>
