<template>
    <section class="content w-75">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                Try Out
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <div class="float-left mt-2">
                                <h3 class="card-title font-weight-bold pt-2">
                                    List Try Out
                                </h3>
                            </div>

                            <div class="float-right mt-1 mr-2">
                                <a
                                    href="/create/try-out"
                                    class="btn btn-primary"
                                    ><i
                                        class="fa fa-plus-circle"
                                        aria-hidden="true"
                                    ></i>
                                    Buat Try Out</a
                                >
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <h4 class="mt-4 ml-3 mb-1">Filter</h4>
                            <form class="form-inline mb-4 ml-3">
                                <b-form-select
                                    id="input-1"
                                    v-model="filterKelas"
                                    :options="optionsKelas"
                                    class="w-25 mr-5"
                                    @change="loadTryout()"
                                ></b-form-select>

                                <input
                                    type="date"
                                    v-model="filterTgl"
                                    @change="loadTryout()"
                                    class="form-control w-25"
                                />
                            </form>

                            <div
                                class="form-group has-search float-left ml-3 mb-3"
                                style="width: 250px"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari berdasarkan judul.."
                                    @keyup="loadTryout(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Judul Try Out</th>
                                        <th>Kelas</th>
                                        <th>Tanggal</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(key, index) in tryouts.data"
                                        :key="key.id"
                                    >
                                        <td>
                                            {{
                                                index +
                                                1 +
                                                (currentPage * 10 - 10)
                                            }}
                                        </td>
                                        <td>{{ key.judul }}</td>
                                        <td>{{ key.kelas.group_name }}</td>
                                        <td>{{ key.tanggal }}</td>
                                        <td>
                                            <a
                                                :href="
                                                    '/try-out/edit/' + key.id
                                                "
                                                v-if="isExpired(key.tanggal) == true"
                                            >
                                                <span
                                                    class="mr-3 bg-success pl-1 pt-1 rounded"
                                                >
                                                    <i
                                                        class="fa fa-edit"
                                                        style="font-size: 18px"
                                                    ></i>
                                                </span>
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                @click="startedTryout()"
                                                v-else
                                            >
                                                <span
                                                    class="mr-3 bg-success pl-1 pt-1 rounded"
                                                >
                                                    <i
                                                        class="fa fa-edit"
                                                        style="font-size: 18px"
                                                    ></i>
                                                </span>
                                            </a>
                                            <a :href="'/try-out/' + key.id">
                                                <span
                                                    class="mr-3 bg-primary pl-1 pt-1 pr-1 rounded"
                                                >
                                                    <i
                                                        class="fa fa-eye"
                                                        style="font-size: 18px"
                                                    ></i>
                                                </span>
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                @click="setRemovedId(key.id)"
                                                v-b-modal.modal-1
                                            >
                                                <span
                                                    class="mr-3 bg-danger pl-1 pt-1 pr-1 rounded"
                                                >
                                                    <i
                                                        class="fa fa-trash-alt"
                                                        style="font-size: 18px"
                                                    ></i>
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->

                        <!-- modal -->
                        <b-modal
                            id="modal-1"
                            hide-backdrop
                            centered
                            title="Konfirmasi"
                            header-bg-variant="primary"
                            cancel-variant="danger"
                            cancel-title="Batal"
                            ok-variant="btn btn-outline-dark"
                            ok-title="Hapus"
                            no-close-on-esc
                            hide-header-close
                            no-close-on-backdrop
                            @ok="removeTryout"
                        >
                            <h3>Hapus Tryout?</h3>
                            <p class="my-4">
                                Anda ingin menghapus Try Out berikut. Data
                                terkait Try Out akan terhapus
                            </p>
                        </b-modal>
                        <!-- end of modal -->

                        <div class="card-footer">
                            <nav aria-label="..." class="float-right">
                                <pagination
                                    :data="tryouts"
                                    @pagination-change-page="loadTryout"
                                >
                                    <span slot="prev-nav">Sebelumnya</span>
                                    <span slot="next-nav">Selanjutnya </span>
                                </pagination>
                            </nav>
                        </div>
                        <!-- /.card-footer-->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            tryouts: [],
            courses: [],
            q: "",
            filterKelas: "",
            filterTgl: "",
            tryoutRemovedId: "",
            currentPage: 1,
            optionsKelas: [{ value: "", text: "Pilih Kelas" }],
        };
    },
    methods: {
        startedTryout(){
            Toast.fire({
                icon: "error",
                title: 'Tidak dapat mengubah data tryout karena tryout sedang berlangsung',
            });

        },
        isExpired(expiryDate) {
            return Date.now() < (new Date(expiryDate).getTime());
        },
        loadTryout(page = 1) {
            this.$Progress.start();

            axios
                .get(
                    "/api/tryout?page=" +
                        page +
                        "&q=" +
                        this.q +
                        "&kelas=" +
                        this.filterKelas +
                        "&tgl=" +
                        this.filterTgl
                )
                .then(({ data }) => {
                    this.tryouts = data;
                    if (this.optionsKelas.length == 1) {
                        for (let idx in data.kelas) {
                            this.optionsKelas.push({
                                value: data.kelas[idx].id,
                                text: data.kelas[idx].group_name,
                            });
                        }
                    }
                });

            this.$Progress.finish();
        },
        setRemovedId(removedId) {
            if (removedId) {
                this.tryoutRemovedId = removedId;
            }
        },
        removeTryout() {
            if (this.tryoutRemovedId) {
                //hit remove api
                axios
                    .delete("/api/tryout/" + this.tryoutRemovedId)
                    .then(({ data }) => {
                        if (data.status) {
                            Toast.fire({
                                icon: "success",
                                title: data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: data.message,
                            });
                        }
                    });

                //reload data
                this.loadTryout();
            }
        },
    },
    created() {
        this.$Progress.start();
        this.loadTryout();
        this.$Progress.finish();
    },
};
</script>
