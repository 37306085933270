import Router from "vue-router";
import Vue from "vue";
import meta from "vue-meta";

Vue.use(Router);
Vue.use(meta);

const routes = [
    // ######################################## START ADMIN ROUTE #########################################
    {
        path: "/dashboard",
        name: "dashboard-admin",
        component: require("./components/Dashboard.vue").default,
        meta: {
            permission: "isAdmin",
        },
    },
    {
        path: "/student-activities",
        name: "student-activities",
        component: require("./components/list/StudentActivity.vue").default,
        meta: {
            permission: "get-student-activities",
        },
    },
    {
        path: "/manage/try-out",
        name: "List-Tryout",
        component: require("./components/tryout/Tryout.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/create/try-out",
        name: "Create-Tryout",
        component: require("./components/tryout/CreateTryout.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/try-out/:id",
        name: "Detail-Tryout",
        component: require("./components/tryout/ViewTryout.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/try-out/edit/:id",
        name: "Edit-Tryout",
        component: require("./components/tryout/EditTryout.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/questions",
        name: "List-Questions",
        component: require("./components/questions/QuestionCourse.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/questions/:id",
        name: "Detail-Questions",
        component: require("./components/questions/Question.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/questions/view/:id",
        name: "View-Questions",
        component: require("./components/questions/QuestionView.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/create-question",
        name: "Create-Questions",
        component: require("./components/questions/CreateQuestion.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/questions/edit/:id",
        name: "Edit-Questions",
        component: require("./components/questions/QuestionEdit.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/accounts",
        name: "List-Accounts",
        component: require("./components/management/Accounts/List.vue").default,
        meta: {
            permission: "get-list-accounts",
        },
    },
    {
        path: "/manage/account/create",
        name: "Create-Accounts",
        component: require("./components/management/Accounts/Create.vue")
            .default,
        meta: {
            permission: "create-account",
        },
    },
    {
        path: "/manage/account/:id/edit",
        name: "Edit-Accounts",
        component: require("./components/management/Accounts/Edit.vue").default,
        meta: {
            permission: "edit-account",
        },
    },
    {
        path: "/manage/account/:id",
        name: "Detail-Accounts",
        component: require("./components/management/Accounts/View.vue").default,
        meta: {
            permission: "detail-account",
        },
    },
    {
        path: "/manage/quizzes",
        name: "List-Quiz",
        component: require("./components/quiz/Subjects.vue").default,
        meta: {
            permission: "get-list-quizzes",
        },
    },
    {
        path: "/manage/quizzes/:id",
        name: "Detail-Quiz",
        component: require("./components/quiz/Quizzes.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/quizzes/:id/create",
        name: "detail-quiz",
        component: require("./components/quiz/QuizCreate.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/quizzes/:id/:quiz_id",
        name: "sub-detail-quiz",
        component: require("./components/quiz/QuizView.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/quizzes/:id/:quiz_id/edit",
        name: "edit-sub-quiz",
        component: require("./components/quiz/QuizEdit.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/account/edit/:id",
        name: "edit-account",
        component: require("./components/management/Accounts/Edit.vue").default,
        meta: {
            permission: "all",
        },
    },
    {
        path: "/manage/teachers",
        name: "list-teacher",
        component: require("./components/management/Teachers/List.vue").default,
        meta: {
            permission: "get-list-teachers",
        },
    },
    {
        path: "/manage/students",
        name: "list-student",
        component: require("./components/management/Students/List.vue").default,
        meta: {
            permission: "get-list-students",
        },
    },
    {
        path: "/manage/groups",
        name: "list-group",
        component: require("./components/management/Groups/List.vue").default,
        meta: {
            permission: "get-list-classes",
        },
    },
    {
        path: "/manage/group/create",
        name: "create-group",
        component: require("./components/management/Groups/Create.vue").default,
        meta: {
            permission: "create-group",
        },
    },
    {
        path: "/manage/group/edit/:id",
        name: "edit-group",
        component: require("./components/management/Groups/Edit.vue").default,
        meta: {
            permission: "edit-group",
        },
    },
    {
        path: "/manage/group/:id",
        name: "detail-group",
        component: require("./components/management/Groups/View.vue").default,
        meta: {
            permission: "detail-group",
        },
    },
    {
        path: "/subjects",
        name: "list-subject",
        component: require("./components/subjects/Subjects.vue").default,
        meta: {
            permission: "get-list-courses",
        },
    },
    {
        path: "/manage/scores",
        name: "list-score",
        component: require("./components/scores/Options.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    {
        path: "/manage/scores/tryouts",
        name: "list-score-tryouts",
        component: require("./components/scores/tryout/ListTryout.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    {
        path: "/manage/scores/tryout/:tryout_id",
        name: "list-score-tryout-detail",
        component: require("./components/scores/tryout/ListScore.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    {
        path: "/manage/scores/quizzes",
        name: "list-score-quizzes",
        component: require("./components/scores/quiz/SubjectsList.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    {
        path: "/manage/scores/quizzes/:id",
        name: "list-score-quiz",
        component: require("./components/scores/quiz/QuizListPerSubject.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    {
        path: "/manage/scores/quizzes/:quiz_id/:subject_id/score",
        name: "score-quiz-participant",
        component: require("./components/scores/quiz/ScoreQuiz.vue").default,
        meta: {
            permission: "get-list-scores",
        },
    },
    // ######################################## END ADMIN ROUTE ###########################################
    // ######################################## START SISWA ROUTE #########################################
    {
        path: "/student/dashboard",
        name: "dashboard-siswa",
        component: require("./components/StudentDashboard.vue").default,
        meta: {
            permission: "isStudent",
        },
    },
    {
        path: "/paket-bimbel",
        name: "paket-bimbel",
        component: require("./components/PaketBimbel.vue").default,
        meta: {
            permission: "paketbimbel-page",
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: require("./components/Profile.vue").default,
        meta: {
            permission: "isStudent",
        },
    },
    {
        path: '/siswa/try-out/list',
        name: 'tryout-siswa-list',
        component: require('./components/tryout-siswa/ListTryout.vue').default,
        meta: {
            permission: "tryout-page" // need permission
        }
    },
    {
        path: '/siswa/try-out/exam/:id',
        component: require('./components/tryout-siswa/ExamTryout.vue').default,
        meta: {
            permission: "tryout-page" // need permission
        }
    },
    {
        path: '/siswa/try-out/pre-exam/:id',
        name: 'tryout-pre-exam',
        component: require('./components/tryout-siswa/PreExamTryout.vue').default,
        meta: {
            permission: "tryout-page" // need permission
        }
    },
    {
        path: '/siswa/try-out/detail/:id',
        component: require('./components/tryout-siswa/DetailTryout.vue').default,
        meta: {
            permission: "tryout-page" // need permission
        }
    },
    {
        path: '/siswa/try-out/pembahasan/:id',
        component: require('./components/tryout-siswa/PembahasanTryout.vue').default,
        meta: {
            permission: "tryout-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/list',
        name: 'quiz-siswa-list',
        component: require('./components/quiz-siswa/SubjectQuiz.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/:id',
        name: 'quiz-siswa-list-exam',
        component: require('./components/quiz-siswa/ListQuiz.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/:id/petunjuk/:quiz_id',
        name: 'quiz-siswa-instruction',
        component: require('./components/quiz-siswa/Petunjuk.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/:id/mengerjakan/:quiz_id',
        name: 'quiz-siswa-mengerjakan',
        component: require('./components/quiz-siswa/ExamQuiz.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/detail/:id',
        component: require('./components/quiz-siswa/DetailQuiz.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/quiz/pembahasan/:id',
        component: require('./components/quiz-siswa/PembahasanQuiz.vue').default,
        meta: {
            permission: "quiz-page" // need permission
        }
    },
    {
        path: '/siswa/scores',
        name: 'myscore',
        component: require('./components/score-student/Options.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    {
        path: '/siswa/scores/tryouts',
        name: 'myscore-tryout',
        component: require('./components/score-student/tryout/ListTryout.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    {
        path: '/siswa/scores/tryout/:tryout_id',
        name: 'myscore-tryout-detail',
        component: require('./components/score-student/tryout/ListScore.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    {
        path: '/siswa/scores/quiz',
        name: 'myscore-quiz',
        component: require('./components/score-student/quiz/SubjectsList.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    {
        path: '/siswa/scores/quiz/:subject_str_id',
        name: 'myscore-quiz-listquiz',
        component: require('./components/score-student/quiz/QuizListPerSubject.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    {
        path: '/siswa/scores/quiz/:quiz_id/:subject_id/score',
        name: 'myscore-quiz-score',
        component: require('./components/score-student/quiz/ScoreQuiz.vue').default,
        meta: {
            permission: "nilai-page" // need permission
        }
    },
    // ######################################## END SISWA ROUTE ###########################################
    {
        path: "*",
        name: "not-found",
        component: require("./components/NotFound.vue").default,
        meta: {
            permission: "all",
        },
    },
]

const router = new Router({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.permission === "all") {
        next();
    } else {
        let permission = to.meta.permission;

        if (permission == "isAdmin") {
            let guard = window.user.type === "admin";
            if (!guard) {
                next({
                    name: "dashboard-siswa",
                });
                return;
            } else next();
        } else if (permission == "isStudent") {
            let guard = window.user.type === "student";
            if (!guard) {
                next({
                    name: "dashboard-admin",
                });
                return;
            } else next();
        } else {
            let guard = window.user.ability.indexOf(permission) !== -1;
            if (!guard) {
                next({
                    name: "not-found",
                });
                return;
            } else next();
        }
    }
});

export default router;
