<template>
  <section class="content w-75">
    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a href="/questions"><i class="fa fa-arrow-left" aria-hidden="true"> </i> Kembali</a>
        </div>
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header header-color text-white">

                <div class="float-left mt-2">
                    <h3 class="card-title font-weight-bold pt-2">Bank Soal {{this.questions.title}}</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <div class="form-group has-search float-left mt-5 ml-3 mb-3" style="width:96%;">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control mb-2" placeholder="Cari soal.." @keyup="loadQuestion(1)" v-model="q">
                </div>


                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Soal</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(key, index) in questions.data.data" :key="key.id">
                          <td>{{(index+1)+(currentPage*10-10)}}</td>
                          <td><div v-html="maxChars(key.soal,60)" class="soal-img"></div></td>
                          <td>
                            <a :href="'/questions/edit/'+key.id">
                              <span class="mr-3 bg-success pl-1 pt-1 rounded">
                                <i class="fa fa-edit" style="font-size:18px;"></i>
                              </span>
                            </a>
                            <a :href="'/questions/view/'+key.id">
                              <span class="mr-3 bg-primary pl-1 pt-1 pr-1 rounded">
                                <i class="fa fa-eye" style="font-size:18px;"></i>
                              </span>
                            </a>
                            <a href="javascript:void(0)" @click="setRemovedId(key.id)" v-b-modal.modal-1>
                              <span class="mr-3 bg-danger pl-1 pt-1 pr-1 rounded">
                                <i class="fa fa-trash-alt" style="font-size:18px;"></i>
                              </span>
                            </a>
                          </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->

              <!-- modal -->
                <b-modal
                id="modal-1"
                hide-backdrop
                centered
                title="Konfirmasi"
                header-bg-variant="primary"
                cancel-variant="danger"
                cancel-title="Batal"
                ok-variant="btn btn-outline-dark"
                ok-title="Hapus"
                no-close-on-esc
                hide-header-close
                no-close-on-backdrop
                @ok="removeSoal"
                >
                  <h3>Hapus Soal?</h3>
                  <p class="my-4">Anda ingin menghapus soal berikut. Soal akan hilang dari bank soal</p>
                </b-modal>
              <!-- end of modal -->


              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                   <pagination :data="questions.data" @pagination-change-page="loadQuestion">
                       <span slot="prev-nav">Sebelumnya</span>
                       <span slot="next-nav">Selanjutnya </span>
                   </pagination>
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<style>
  .soal-img p img{
    width:200px;
    height:auto;
  }
</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              questions: {},
              courses: {},
              q: '',
              soalRemovedId: '',
              items: [
                {
                    text: 'Soal',
                    active: true
                }
              ],
              currentPage: 1,
            }
        },
        methods: {

          loadQuestion(page=1){
              this.currentPage = page;
              this.$Progress.start();

              if(this.q){
                axios.get('/api/questions/'+this.$route.params.id+'?page=' + page+'&q='+this.q).then(({data}) => (this.questions = data));
              }else{
                axios.get('/api/questions/'+this.$route.params.id+'?page=' + page).then(({data}) => (this.questions = data));
              }

              this.$Progress.finish();
          },
          setRemovedId(removedId){
            if(removedId){
              this.soalRemovedId = removedId
            }
          },
          removeSoal(){

            if(this.soalRemovedId){
              //hit remove api
              axios.delete('/api/questions/'+this.soalRemovedId).then(({data}) => alert(data.status))

              //reload data
              this.loadQuestion();
            }

          },
          strippedHtml(text) {
              let regex = /<\/?(?!img)\w*\b[^>]*>/ig;

              return '<p>'+text.replace(regex, "")+'</img></p>';
          },
          maxChars(str, max){
              var tags = 0,
              sQuotes = 0,
              dQuotes = 0,
              char,
              count = 0,
              result = [];
          for (var i = 0, len = str.length; i < len; ++i) {
            char = str.charAt(i);
            switch(char) {
              case '<':
                if (!sQuotes && !dQuotes) {
                  ++tags;
                  result.push(char);
                  continue;
                }
                break;
              case '>':
                if (!sQuotes && !dQuotes) {
                  --tags;
                  result.push(char);
                  continue;
                }
                break;
              case "'":
                if (tags && !dQuotes)
                  sQuotes = !sQuotes;
                break;
              case '"':
                if (tags && !sQuotes)
                  dQuotes = !dQuotes;
                break;
            }
            if (tags) {
              result.push(char);
            } else {
              if (++count < max)
                result.push(char);
            }
          }
          let r = result.join('');

          return r+'...';
          }
        },
        created() {
            this.$Progress.start();
            this.loadQuestion();
            this.$Progress.finish();
        }
    }
</script>
