<template>
    <a href="javascript:void(0)" class="btn btn-link p-0 m-0">
        <a @click="$router.go(-1)" class="text-dark h5">
            <i class="fas fa-angle-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;Kembali
        </a>
    </a>
</template>

<script>
export default {
};
</script>
