<template>
  <section class="content w-100 mb-5">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-header bg-primary">
              <h4>Keterangan</h4>
            </div>
            <div class="card-body text-center" style="margin:0; padding:0;">
                <table class="table table-bordered text-left mb-4" style="min-width:450px;">
                      <tbody>
                        <tr>
                          <td style="width:40%;"><b>Mata Pelajaran</b></td>
                          <td style="width:60%;">{{subject_name}}</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Jenis</b></td>
                          <td style="width:60%;">Kuis</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Nilai</b></td>
                          <td style="width:60%;">{{score}}/{{maxScore}}</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Pembahasan</b></td>
                          <td style="width:60%;">
                            <a :href="`/siswa/quiz/pembahasan/${pembahasan}`">Lihat Pembahasan</a>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              <a href="/siswa/quiz/list" class="btn btn-lg btn-primary mb-4">Selesai</a>
            </div>
          </div>
      </div>

    </div>
  </section>
</template>
<style scoped>

</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              subject_name: '',
              score: 0,
              maxScore: 0,
              pembahasan: false,

            }
        },
        methods: {
            initial(){
                axios.get('/api/siswa/quiz-result/'+this.$route.params.id).then(({data}) => {
                      this.subject_name = data.data.subject_name
                      this.score = data.data.score
                      this.maxScore = data.data.max_score
                      this.pembahasan = data.data.pembahasan
                });

            },

        },
        created() {
            this.$Progress.start();
            this.initial();
            this.$Progress.finish();
        }
    }
</script>
