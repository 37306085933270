<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h4 class="">Akun / Edit Akun</h4>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <button-back />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">Edit Akun</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <form
                                @submit.prevent="
                                    detail.status == 'SISWA'
                                        ? updateStudent()
                                        : updateTeacher()
                                "
                            >
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Tipe Akun</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <div
                                            class="form-group"
                                        >
                                            <label for="RoleSelect"
                                                >Role</label
                                            >
                                            <input
                                                v-model="detail.status"
                                                type="text"
                                                class="form-control"
                                                id="RoleSelect"
                                                readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div
                                            class="form-group"
                                            v-if="type == 'student'"
                                        >
                                            <label for="classSelect"
                                                >Kelas</label
                                            >
                                            <select
                                                v-model="detail.group_id"
                                                class="custom-select"
                                                id="classSelect"
                                                disabled
                                            >
                                                <option
                                                    v-for="clas in options.groups"
                                                    :value="clas.id"
                                                    :key="clas.str_id"
                                                >
                                                    {{ clas.group_name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            class="form-group"
                                            v-if="type == 'teacher'"
                                        >
                                            <label for="classSelect"
                                                >Mata Pelajaran</label
                                            >
                                            <select
                                                v-model="detail.subject_id"
                                                class="custom-select"
                                                id="courseSelect"
                                                disabled
                                            >
                                                <option
                                                    v-for="course in options.subjects"
                                                    :value="course.id"
                                                    :key="course.id"
                                                >
                                                    {{ course.subjects_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <h4>Profil</h4>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label
                                                for="name"
                                                v-if="type == 'student'"
                                                >Nama Siswa</label
                                            >
                                            <label
                                                for="name"
                                                v-if="type == 'teacher'"
                                                >Nama Pengajar</label
                                            >
                                            <input
                                                v-model="detail.name"
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label
                                                for="id"
                                                v-if="type == 'student'"
                                                >ID Siswa</label
                                            >
                                            <label
                                                for="id"
                                                v-if="type == 'teacher'"
                                                >ID Pengajar</label
                                            >
                                            <input
                                                v-model="detail.str_id"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="password"
                                                >Password</label
                                            >
                                            <input
                                                v-model="detail.password"
                                                type="password"
                                                class="form-control"
                                                id="password"
                                                autocomplete="false"
                                            />
                                            <small
                                                >Masukkan password jika ingin
                                                mengubah password</small
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="type == 'student'">
                                    <div class="col-md-12">
                                        <h4>Rentang Waktu Belajar</h4>
                                    </div>
                                </div>
                                <div class="row" v-if="type == 'student'">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="date-start"
                                                >Tanggal Masuk</label
                                            >
                                            <input
                                                v-model="detail.start_date"
                                                type="date"
                                                class="form-control"
                                                id="date-start"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="date-end"
                                                >Tanggal Selesai</label
                                            >
                                            <input
                                                v-model="detail.end_date"
                                                type="date"
                                                class="form-control"
                                                id="date-end"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="type == 'student'">
                                    <div class="col-md-12">
                                        <h4>Rincian Pembelajaran</h4>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="type == 'student'">
                                    <div class="col-md-5">
                                        <label for="date-end"
                                            >Mata Pelajaran</label
                                        >
                                        <div
                                            v-for="option in detail.subjects_list"
                                            :key="option"
                                            class="row"
                                        >
                                            <div class="col mb-1">
                                                <b-form-input
                                                    :value="option"
                                                    readonly
                                                ></b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="type == 'student'">
                                    <div class="col-md-5">
                                        <label for="total_quiz"
                                            >Jumlah Kuis</label
                                        >
                                        <b-form-input
                                            id="total_quiz"
                                            v-model="detail.total_quiz"
                                            value="0/48"
                                            readonly
                                        ></b-form-input>
                                    </div>
                                    <div class="col-md-5">
                                        <label for="total_tryout"
                                            >Jumlah Try Out</label
                                        >
                                        <b-form-input
                                            id="total_tryout"
                                            v-model="detail.total_tryout"
                                            value="0/48"
                                            readonly
                                        ></b-form-input>
                                    </div>
                                </div>
                                <div class="row mt-5 px-5">
                                    <b-col class="text-center">
                                        <b-button
                                            variant="outline-secondary"
                                            size="lg"
                                            class="px-5"
                                            :to="back"
                                            >Batal</b-button
                                        >
                                        <b-button
                                            variant="primary"
                                            size="lg"
                                            class="px-5"
                                            type="submit"
                                            >Simpan</b-button
                                        >
                                    </b-col>
                                </div>
                            </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            detail: {},
            type: "",
            options: {
                groups: [],
                subjects: []
            },
            nextID: 0,
        };
    },
    methods: {
        getDataDetail(id, type) {
            axios.get(`/api/account/${type}/${id}`).then(({ data }) => {
                this.detail = data.data;
                this.detail.status = data.data.status.toUpperCase();
            });

            if (type == "student") {
                this.loadGroups()
            } else if (type == "teacher") {
                this.loadSubjects()
            }
        },
        loadGroups() {
            axios
                .get("/api/group/list")
                .then(({ data }) => (this.options.groups = data.data));
        },
        loadSubjects() {
            axios
                .get("/api/subject/list")
                .then(({ data }) => (this.options.subjects = data.data));
        },
        async onChangeClassStudent(event) {
            let options = event.target.options;
            if (options.selectedIndex > -1) {
                let prefix =
                    options[options.selectedIndex].getAttribute("code");
                let id = options[options.selectedIndex].getAttribute("value");
                await this.getNextIDClass(id);
                this.detail.str_id = prefix + this.nextID;

                await this.loadGroupSubjects(id);
            }
        },
        async getNextIDClass(id) {
            await axios
                .get(`/api/group/total-students/${id}`)
                .then(({ data }) => {
                    let datas = data.data + 1;
                    let panjang = String(data.data).length;
                    let max = 4;
                    let result = 0;

                    if (panjang == max) {
                        this.nextID = datas;
                    } else {
                        for (let i = 0; i < max - panjang; i++) {
                            result += "0";
                        }
                        result += datas;
                        this.nextID = result;
                    }
                });
        },
        async loadGroupSubjects(id) {
            axios
                .get(`/api/group/subject/${id}`)
                .then(({ data }) => (this.detail.subjects_list = data.data));
        },
        updateStudent() {
            axios
                .put(`/api/students/${this.detail.id}`, this.detail)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/accounts`,
                            query: { type: `student` }
                        })
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                })
        },
        updateTeacher() {
            axios
                .put(`/api/teachers/${this.detail.id}`, this.detail)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/accounts`,
                            query: { type: `teacher` }
                        })
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                })
        }
    },
    computed: {
        back() {
            if (
                this.client ||
                !this.$routerHistory ||
                !this.$routerHistory.hasPrevious()
            ) {
                // probably ssr, or hasn't navigated yet.
                return { path: "/" };
            }
            return { path: this.$routerHistory.previous().path };
        },
    },
    created() {
        this.getDataDetail(this.$route.params.id, this.$route.query.type);
        this.type = this.$route.query.type;
    },
};
</script>
