<template>
  <section class="content w-75">

    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a :href="'/questions/'+this.subjectId"><i class="fa fa-arrow-left" aria-hidden="true"> </i> Kembali</a>
        </div>

        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header bg-info">

                <div class="float-left mt-2">
                    <h3 class="card-title">Form Buat Soal</h3>
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">


                    <template>
                    <div class="mt-4 mb-2 ml-3 mr-3">
                        <b-form @submit="onSubmit" v-if="show">

                            <b-form-group id="input-group-1" label="Mata Pelajaran*" label-for="input-1">
                                <b-form-select
                                id="input-1"
                                v-model="form.mata_pelajaran"
                                :options="optionsMapel"
                                disabled
                                class="w-25"
                                ></b-form-select>
                            </b-form-group>

                            <b-form-group id="input-group-2" label="Soal*" label-for="input-2">
                                <vue-editor
                                id="editor"
                                useCustomImageHandler
                                @image-added="handleImageAdded"
                                v-model="form.soal"
                                >
                                </vue-editor>
                            </b-form-group>
                            <b-form-group
                                label="Opsi*"
                                label-for="input-3"
                                id="input-group-3"
                            >
                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-3"
                                        v-model="form.opsi1"
                                        placeholder="Tulis opsi a"
                                        class="w-100"
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file
                                        v-model="form.image_opsi1"
                                        plain
                                        accept="image/*"
                                        v-on:change="setImage('1', $event)"
                                        >
                                        </b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi1" target="_blank">{{this.form.image_opsi1}}</a>
                                    </div>
                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-4"
                                        v-model="form.opsi2"
                                        placeholder="Tulis opsi b"
                                        class="w-100"
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file
                                        v-model="form.image_opsi2"
                                        plain
                                        accept="image/*"
                                        v-on:change="setImage('2', $event)"
                                        ></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi2" target="_blank">{{this.form.image_opsi2}}</a>
                                    </div>

                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-5"
                                        v-model="form.opsi3"
                                        placeholder="Tulis opsi c"
                                        class="w-100"
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file
                                        v-model="form.image_opsi3"
                                        plain
                                        accept="image/*"
                                        v-on:change="setImage('3', $event)"
                                        ></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi3" target="_blank">{{this.form.image_opsi3}}</a>
                                    </div>

                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-6"
                                        v-model="form.opsi4"
                                        placeholder="Tulis opsi d"
                                        class="w-100"
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file
                                        v-model="form.image_opsi4"
                                        plain
                                        accept="image/*"
                                        v-on:change="setImage('4', $event)"
                                        ></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi4" target="_blank">{{this.form.image_opsi4}}</a>
                                    </div>


                                </div>

                                <div class='form-inline'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-7"
                                        v-model="form.opsi5"
                                        placeholder="Tulis opsi e"
                                        class="w-100"
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file
                                        v-model="form.image_opsi5"
                                        plain
                                        accept="image/*"
                                        v-on:change="setImage('5', $event)"
                                        ></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi5" target="_blank">{{this.form.image_opsi5}}</a>
                                    </div>

                                </div>
                            </b-form-group>
                            <!-- subject type jawaban benar -->
                            <div v-if="subjectType == 'jawaban_benar'">
                                <b-form-group id="input-group-4" label="Kunci Jawaban*" label-for="input-7">
                                    <b-form-select
                                    id="input-8"
                                    v-model="form.kunci_jawaban"
                                    :options="options_kunci_jawaban"
                                    required
                                    class="w-25"
                                    ></b-form-select>
                                </b-form-group>

                                <b-form-group id="input-group-5" label="Pembahasan" label-for="input-9">
                                    <vue-editor
                                    id="editor2"
                                    useCustomImageHandler
                                    @image-added="handleImageAdded"
                                    v-model="form.pembahasan"
                                    >
                                    </vue-editor>
                                </b-form-group>
                            </div>

                            <!-- subject type point -->
                            <div v-if="subjectType == 'point'">
                                <b-form-group id="input-group-6" label="Point Jawaban*" label-for="input-10">
                                    <table class="table table-borderless w-50">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="col-3">Opsi</th>
                                        <th scope="col" class="col-3">Point</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="col-3">A</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi1"
                                                class="w-50"
                                                required
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">B</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi2"
                                                class="w-50"
                                                required
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">C</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi3"
                                                class="w-50"
                                                required
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">D</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi4"
                                                class="w-50"
                                                required
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">E</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi5"
                                                class="w-50"
                                                required
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </b-form-group>
                            </div>

                            <div class="container">
                            <div class="row">
                                <div class="col text-center pt-4 pb-4">
                                    <a :href="'/questions/'+this.subjectId" class="btn btn-lg btn-outline-dark btn-light">Batal</a>
                                    <b-button type="submit" variant="primary" class="btn-lg btn-primary" :disabled="isDisabled">Simpan</b-button>
                                </div>
                            </div>
                            </div>

                        </b-form>

                    </div>
                    </template>




              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<style scoped>
  #input-group-3 .form-control {
    border:none;
    border-bottom: 1px solid #1890ff;
    padding: 5px 10px;
    outline: none;
    margin-bottom: 15px;
   }
    #input-group-3 .form-control:focus{
    border-color: #ced4da;
    box-shadow: none;
    }
</style>
<script>
    import { VueEditor } from "vue2-editor";
    import axios from 'axios';

    export default {
        components: {
            VueEditor
        },
        data () {
            return {
              loc : location.host,
              courses: {},
              q: '',
              subjectType : null,
              subjectId : null,
              items: [
                    {
                        text: 'Soal',
                        href: 'questions'
                    },
                    {
                        text: 'Buat Soal',
                        active: true
                    }
                ],
                selectedMapel: null,
                optionsMapel: [],
                optionsMapelType : [],
                selectedOpsi : null,
                options_kunci_jawaban: [
                    { value: null, text: 'Pilih Opsi' },
                    { value: 'A', text: 'A' },
                    { value: 'B', text: 'B' },
                    { value: 'C', text: 'C' },
                    { value: 'D', text: 'D' },
                    { value: 'E', text: 'E' },
                ],
                form: {
                    mata_pelajaran: null,
                    soal: '',
                    opsi1: '',
                    opsi2: '',
                    opsi3: '',
                    opsi4: '',
                    opsi5: '',
                    image_opsi1: '',
                    image_opsi2: '',
                    image_opsi3: '',
                    image_opsi4: '',
                    image_opsi5: '',
                    point_opsi1: '',
                    point_opsi2: '',
                    point_opsi3: '',
                    point_opsi4: '',
                    point_opsi5: '',
                    kunci_jawaban: null,
                    pembahasan: ''

                },
                show: true,
                isDisabled: false,
            }
        },
        methods: {
            handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
                // An example of using FormData
                // NOTE: Your key could be different such as:
                // formData.append('file', file)

                var formData = new FormData();
                formData.append("image", file);

                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }

                axios({
                    url: loc+"/api/image-upload",
                    method: "POST",
                    data: formData
                })
                    .then(result => {

                    const url = result.data.url; // Get url from response

                    Editor.insertEmbed(cursorLocation, "image", loc+url);
                    resetUploader();
                    })
                    .catch(err => {
                    console.log(err);
                    });
                },
            getData(){
                axios.get('/api/questions/edit/view/'+this.$route.params.id).then(({data}) => {
                        //set subject
                        this.subjectId = data.subject.id
                        this.optionsMapel.push({
                            value: null,
                            text: data.subject.subjects_name
                            })
                        //set subject type
                        this.subjectType = data.subject.scoring_system
                        //set soal
                        this.form.soal = data.soal

                        for(let i=1; i<=5; i++){
                            this.form['opsi'+i] = data.opsi[i-1].keterangan_opsi
                            this.form['image_opsi'+i] = data.opsi[i-1].gambar_opsi

                            //set selected kunci jawaban
                            if(data.subject.scoring_system == 'jawaban_benar'){
                                if(data.opsi[i-1].id == data.kunci_jawaban){
                                    this.form.kunci_jawaban = data.opsi[i-1].opsi_name
                                }

                            }

                            //set opsi point
                             if(data.subject.scoring_system == 'point'){
                                 this.form['point_opsi'+i] = data.opsi[i-1].point
                             }

                        }

                        //set pembahasan
                        if(data.subject.scoring_system == 'jawaban_benar'){
                            this.form.pembahasan = data.pembahasan
                        }


                })
            },
            setImage(property, event){
                let files = event.target.files || event.dataTransfer.files;
                if (!files.length)
                    return;

                let file = files[0];

                if(property == '1'){
                    this.form.image_opsi1 = file
                    console.log(this.form.image_opsi1)
                }
                if(property == '2'){
                    this.form.image_opsi2 = file
                }
                if(property == '3'){
                    this.form.image_opsi3 = file
                }
                if(property == '4'){
                    this.form.image_opsi4 = file
                }
                if(property == '5'){
                    this.form.image_opsi5 = file
                }


            },
            onSubmit(event) {
                event.preventDefault()
                let isValidated = true;

                //check soal
                if(this.form.soal == ''){
                    isValidated = false
                    alert('Soal tidak boleh kosong');
                }

                //check opsi
                for(let i=1; i<=5; i++){
                    if(this.opsi+i == '' && this.image_opsi+i == ''){
                        isValidated = false;
                    }
                }

                //reject if opsi&image null
                if(isValidated == false){
                    alert('Opsi dan Gambar tidak boleh kosong, isi salah satu atau keduanya');
                }

                //submit
                const fd = new FormData();
                fd.append('mata_pelajaran', this.form.mata_pelajaran);
                fd.append('soal', this.form.soal);
                fd.append('opsi1', this.form.opsi1);
                fd.append('opsi2', this.form.opsi2);
                fd.append('opsi3', this.form.opsi3);
                fd.append('opsi4', this.form.opsi4);
                fd.append('opsi5', this.form.opsi5);
                fd.append('image_opsi1', this.form.image_opsi1);
                fd.append('image_opsi2', this.form.image_opsi2);
                fd.append('image_opsi3', this.form.image_opsi3);
                fd.append('image_opsi4', this.form.image_opsi4);
                fd.append('image_opsi5', this.form.image_opsi5);

                if(this.subjectType == 'point'){
                    fd.append('point_opsi1', this.form.point_opsi1);
                    fd.append('point_opsi2', this.form.point_opsi2);
                    fd.append('point_opsi3', this.form.point_opsi3);
                    fd.append('point_opsi4', this.form.point_opsi4);
                    fd.append('point_opsi5', this.form.point_opsi5);
                }

                if(this.subjectType == 'jawaban_benar'){
                    fd.append('kunci_jawaban', this.form.kunci_jawaban);
                    fd.append('pembahasan', this.form.pembahasan);
                }

                axios.post('/api/questions/edit/'+this.$route.params.id, fd).then(({data}) => {
                        if(data.status){
                            Toast.fire({
                                icon: 'success',
                                title: data.message
                            });
                            window.location.reload()
                        }else{
                            Toast.fire({
                                icon: 'error',
                                title: data.message
                            });
                        }

                    })

            },

        },
        created() {
            //location
            let host = location.host;
            if(!host.includes("http://") && !host.includes("https://")){
                this.loc = 'http://'+host
            }
            this.$Progress.start();
            this.getData();
            this.$Progress.finish();
        }
    }
</script>
