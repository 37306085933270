<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="items"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <button-back />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">Form Buat Kelas</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <form @submit.prevent="createGroup">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">ID Kelas</label>
                                            <input
                                                v-model="form.str_id"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                placeholder="Tulis ID Kelas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name">Kelas</label>
                                            <input
                                                v-model="form.group_name"
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                placeholder="Nama Kelas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">Kode Kelas</label>
                                            <input
                                                v-model="form.prefix_code"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                placeholder="Contoh: Akpol (AKP)"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name"
                                                >Mata Pelajaran</label
                                            ><br />
                                            <small
                                                >Ceklist mata pelajaran yang
                                                akan disajikan dalam
                                                kelas.</small
                                            >
                                            <div class="border rounded-sm p-3">
                                                <div v-for="subject in options.subjects" :key="subject.id">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" :value="{ id: subject.id, text: subject.subjects_name }" v-model="selected" :id="subject.id">
                                                        <label class="custom-control-label" :for="subject.id">{{ subject.subjects_name }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name"
                                                >Mata Pelajaran</label
                                            ><br />
                                            <span v-for="sel in selected" :key="sel.id">{{ sel.text }}<br/></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5 px-5">
                                    <b-col class="text-center">
                                        <b-button
                                            variant="outline-secondary"
                                            size="lg"
                                            class="px-5"
                                            :to="back"
                                            >Batal</b-button
                                        >
                                        <b-button
                                            variant="primary"
                                            size="lg"
                                            class="px-5"
                                            type="submit"
                                            >Simpan</b-button
                                        >
                                    </b-col>
                                </div>
                            </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: new Form({
                str_id: '',
                group_name: '',
                prefix_code: "",
                subjects: []
            }),
            selected: [],
            options: {
                subjects: [],
            },
            items: [
                {
                    text: "Kelas",
                    active: true,
                    href: "manage/groups",
                },
                {
                    text: "Buat Kelas",
                    active: false,
                },
            ],
        };
    },
    methods: {
        loadSubjects(id) {
            axios
                .get("/api/subject/list")
                .then(({ data }) => (this.options.subjects = data.data));
        },
        createGroup() {
            let subjects = this.selected.map(function(item) {
                return item.id;
            })
            this.form.subjects = subjects

            this.$Progress.start();
            this.form
                .post("/api/groups")
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/groups`
                        })
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                        console.log(2)
                    } else {
                        console.log(3)
                    }
                });
        }
    },
    computed: {
        back() {
            if (
                this.client ||
                !this.$routerHistory ||
                !this.$routerHistory.hasPrevious()
            ) {
                // probably ssr, or hasn't navigated yet.
                return { path: "/" };
            }
            return { path: this.$routerHistory.previous().path };
        }
    },
    created() {
        this.loadSubjects();
    }
};
</script>
