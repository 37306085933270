<template>
  <section class="content w-100">
    <div class="container">
      <div class="row mb-2">
        <div class="col-12">
            <h6><b>Soal {{tryoutQuest.title}}</b></h6>
        </div>
        <div class="col-12 text-center" style="font-size:12px;">
          <p><i class="fas fa-flag mr-2" style="font-size:14px; cursor:pointer;" v-on:click="setFlag()"></i>Tandai Soal Ini.</p>
        </div>
      </div>
      <div class="row">
        
        <div class="col-8">
          <div class="soal mb-2">
           <span class="number">{{tryoutQuest.currentNumber}}. </span> <span class="content" v-html="tryoutQuest.soal"></span>
          </div>
          <div class="answers form-check rounded" style="display:inline-block;" 
          v-for="data in tryoutQuest.choices" :key="data.id">
            <input class="form-check-input" type="radio" v-bind:value="data.id" v-model="jawaban" name="jawaban" @click="setJawaban(data.id)">
            <p>{{data.letter}}. {{data.keterangan}}</p>
            <p v-if="data.gambar_opsi != ''">
              <img :src="host+data.gambar_opsi" style="max-width:100%;">
            </p>
          </div>
          <div class="text-right mt-3" style="max-width:90%;">
            <p v-if="tryoutQuest.currentNumber < tryoutQuest.totalQuest"><a href="javascript:void(0)" @click="goToNextNumber(0)">Selanjutnya</a></p>
          </div>
        </div>
        <div class="col-4">
          <div class="question-navigation">

            <div class="question-box border-bottom">
              <span class="float-left">
                <b>Waktu</b>
              </span>
              <span class="float-right">
                <Countdown :date="end" @onFinish="finish()" v-if="end != 0"></Countdown>
              </span>
            </div>

            <div class="question-box border-bottom">
              <span class="float-left">
                <b>Soal</b>
              </span>
            </div>
            <div class="question-boxes p-3" v-for="index in tryoutQuest.totalQuest" :key="index">
              <div 
              class="box border text-center" 
              @click="goToNextNumber(index)"
              v-bind:class="[tryoutQuest.flags[index] != '' ? tryoutQuest.flags[index] : '']"
              >
                  {{index}}
              </div>
            </div>
            <div class="finish-button text-center">
              <button class="btn btn-md btn-primary" @click="finish" :disabled="disabled">
                Selesai
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
  .soal span{
    display: inline;
  }
  .soal span :nth-last-child(1){
    display: inline;
  }
  .soal img{
    max-width:90%;
  }
</style>
<style scoped>
  .answers{
    background-color: white;
    margin-left:20px;
    max-width:90%;
    min-width:90%;
    padding:20px 20px 5px 35px;
    margin-bottom:13px;
  }
  .finish-button{
    margin-top:40px;
  }
  .question-boxes{
    display:inline-block;
    margin-right: -20px;
    margin-bottom: -30px;
  }
  .box{
    padding:3px;
    min-height:30px;
    min-width:28px;
    width:28px;
    height:30px;
    font-size:14px;
    cursor:pointer;
  }
  .question-box{
    min-height:50px;
    padding:12px;
  }
  .question-navigation{
    background-color: white;
    min-height:500px;
    margin-bottom:100px;
  }
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import Countdown from '../countdown/Countdown';

    export default {
      components: {
        Countdown
      },
        data () {
            return {
              host: '',
              disabled: false,
              end: 0,
              jawaban: '',
              subject_id: null,
              soal_id: null,
              tryoutQuest : {
                title: '',
                soal: '',
                choices: [],
                totalQuest: 0,
                currentNumber: 1,
                flags: {},
              },
            }
        },
        methods: {
          setJawaban(jawaban){
            this.jawaban = jawaban
            
          },
          goToNextNumber(nextNumber){
              if(nextNumber == 0){
                var nextNumber = this.tryoutQuest.currentNumber+1
              }
              this.tryoutQuest.choices = []
              this.tryoutQuest.soal = ''
              
              axios.post('/api/siswa/tryout/save-go-to-next', {
                  id: this.$route.params.id,
                  subject_id: this.subject_id,
                  answer_id: this.jawaban,
                  soal_id: this.soal_id,
                  next_number: nextNumber
                }).then(({data}) => {
                if(data.status){
                  if(this.jawaban != ''){
                    Vue.set(this.tryoutQuest.flags, this.tryoutQuest.currentNumber, 'bg-primary');
                  }

                  this.soal_id = data.nextQuest.id
                  this.tryoutQuest.currentNumber = nextNumber
                  this.jawaban = ''
                  this.tryoutQuest.soal = data.nextQuest.soal
                  for(let i=0; i<data.nextQuest.opsi.length; i++){
                    this.tryoutQuest.choices.push({
                      id: data.nextQuest.opsi[i].id,
                      letter: data.nextQuest.opsi[i].opsi_name,
                      keterangan: data.nextQuest.opsi[i].keterangan_opsi,
                      gambar_opsi: data.nextQuest.opsi[i].gambar_opsi
                    });
                  }

                  if(data.nextAnswer){
                    this.jawaban = data.nextAnswer.jawaban
                  }

                }else{
                    window.location.reload()
                }

            })
          },
          finish(){
            this.disabled = true
            axios.post('/api/siswa/tryout/finish', {
              id: this.$route.params.id,
              subject_id: this.subject_id,
              soal_id: this.soal_id,
              answer_id: this.jawaban,
              }).then(({data}) => {
                if(data.status){
                    Toast.fire({
                        icon: 'success',
                        title: 'Mata pelajaran ini berhasil diselesaikan'
                    });
                    window.location = '/siswa/try-out/pre-exam/'+this.$route.params.id
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: 'Gagal'
                    });
                }

            })

          },
          initialData(){
                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }
                this.host = loc+'/storage/images/'

                axios.get('/api/siswa/tryout/exam/'+this.$route.params.id).then(({data}) => {
                   this.tryoutQuest.choices = []
                   if(data.status == false){
                    //rediect to the next pre exam if subject is finished
                    if(data.is_subject_finished){
                      window.location = '/siswa/try-out/pre-exam/'+this.$route.params.id
                    }
                   }else{
                     //show tryout data
                      this.tryoutQuest.title = data.title
                      this.tryoutQuest.totalQuest = data.totalQuest
                      this.end = data.countdown
                      this.tryoutQuest.soal = data.firstQuest.soal
                      this.subject_id = data.subject_id
                      this.soal_id = data.firstQuest.id

                      //opsi
                      for(let i=0; i<data.firstQuest.opsi.length; i++){
                        this.tryoutQuest.choices.push({
                          id: data.firstQuest.opsi[i].id,
                          letter: data.firstQuest.opsi[i].opsi_name,
                          keterangan: data.firstQuest.opsi[i].keterangan_opsi,
                          gambar_opsi: data.firstQuest.opsi[i].gambar_opsi
                        });
                      }

                      if(data.firstAnswer){
                        this.jawaban = data.firstAnswer.jawaban
                      }

                      //set answered tryout
                      for(let i=0; i<data.totalQuest; i++){
                        if(data.finishedQuest[i]){
                          Vue.set(this.tryoutQuest.flags, i+1, 'bg-primary');
                        }else{
                          Vue.set(this.tryoutQuest.flags, i+1, '');
                        }
                      }

                   }

                });
          },
          setFlag(){
              let index = this.tryoutQuest.currentNumber;
              Vue.set(this.tryoutQuest.flags, index, 'bg-danger');
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
