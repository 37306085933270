<template>
  <section class="content w-100 mb-5">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-header bg-primary">
              <h4>Keterangan</h4>
            </div>
            <div class="card-body text-center" style="margin:0; padding:0;">
                <table class="table table-bordered text-left mb-4" style="min-width:450px;">
                      <tbody>
                        <tr>
                          <td style="width:40%;"><b>Judul Tryout</b></td>
                          <td style="width:60%;">{{title}}</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Jenis</b></td>
                          <td style="width:60%;">Try Out</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Nilai</b></td>
                          <td style="width:60%;">{{score}}/{{maxScore}}</td>
                        </tr>
                        <tr>
                          <td style="width:40%;"><b>Pembahasan</b></td>
                          <td style="width:60%;">
                            <a :href="'/siswa/try-out/pembahasan/'+this.$route.params.id" v-if="pembahasan">Lihat Pembahasan</a>
                            <a href="javascript:void(0)" v-else>Lihat Pembahasan</a>
                          </td>
                        </tr>
                      </tbody>
                  </table>
              <a href="/siswa/try-out/list" class="btn btn-lg btn-primary mb-4">Selesai</a>
            </div>
          </div>
      </div>

    </div>
  </section>
</template>
<style scoped>

</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              title: '',
              score: 0,
              maxScore: 0,
              pembahasan: false,

            }
        },
        methods: {
            initial(){
                axios.get('/api/siswa/tryout/result/'+this.$route.params.id).then(({data}) => {
                    if(data.status){
                      this.title = data.title
                      this.score = data.score.score
                      this.maxScore = data.score.maxScore
                      this.pembahasan = data.pembahasan
                    }else{
                      window.location = '/siswa/try-out/pre-exam/'+this.$route.params.id
                    }

                });

            },

        },
        created() {
            this.$Progress.start();
            this.initial();
            this.$Progress.finish();
        }
    }
</script>
