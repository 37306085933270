<template>
  <section class="content w-75">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <h5 class="mb-4">List Kuis</h5>
            <div class="card">
              <div class="card-header header-color">

                <div class="float-left mt-2">
                    <h3 class="card-title">Kuis</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">

                <div class="container">
                    <div class="row">

                        <div class="col-md-6 p-0" v-for="(key, index) in quizzes" :key="key.id">
                            <div :class="{'border-bottom border-right ': index % 2 === 0, 'border-bottom border-left': index % 2 !== 0 }">
                                <div class="quiz-card p-3 pt-4">
                                    <div class="quiz-title">
                                        <h4 class="mb-2"><b>{{key.quiz_title}}</b></h4>
                                    </div>
                                    <div class="pertemuan">
                                        <i class="fas fa-book-open mr-2" style="font-size:14px;"></i>Pertemuan {{index + 1}}
                                    </div>
                                    <div class="nilai-text text-center">
                                        <h6>Nilai</h6>
                                    </div>
                                    <div class="waktu">
                                        <i class="fas fa-hourglass-start mr-2" style="font-size:14px;"></i> {{key.waktu_pengerjaan}} Menit
                                    </div>
                                    <div class="nilai text-center">
                                        <h3>
                                            <strong>
                                                {{ key.quiz_history.length > 0 ? key.quiz_history[0].total_score : 0 }}
                                            </strong>
                                        </h3>
                                    </div>
                                    <div class="soal">
                                        <i class="fas fa-file-pdf mr-2" style="font-size:14px;"></i> {{key.soal_count}} Soal
                                    </div>
                                    <div class="button-quiz text-center">
                                        <a :href="`/siswa/quiz/${subjectID}/petunjuk/${key.id}`"
                                        :class="key.quiz_history.length > 0 ? 'btn-success' : 'btn-primary' " class="btn btn-md mb-3" style="min-width:130px;">
                                        {{ key.quiz_history.length > 0 ? 'Ulangi' : 'Mulai'}}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

              </div>
              <!-- /.card-body -->

              <div class="card-footer">

              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<style scoped>
.quiz-card{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(40px, auto);
}
.quiz-card p{
  max-width:80%;
  font-size:12px;
}
.quiz-title {
  grid-column-start: 1;
  grid-column-end: 3;
}
.nilai {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
}
.button-quiz {
    grid-row-start: 5;
    grid-column-start: 1;
    grid-column-end: 3;
}
</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              quizzes: {},
              subjectID: ""
            }
        },
        methods: {
            listQuiz(){
                this.$Progress.start();

                axios
                    .get("/api/siswa/quiz-list/"+this.$route.params.id)
                    .then(({ data }) => (this.quizzes = data.data));

                    this.subjectID = this.$route.params.id

                this.$Progress.finish();
            },
        },
        mounted() {
            this.listQuiz()
        },
        created() {
            this.$Progress.start();

            this.$Progress.finish();
        }
    }
</script>
