<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->
            <ButtonBack />
            <div class="row mt-3">
                <div class="col-8">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <div class="float-left mt-2">
                                <h3 class="card-title font-weight-bold pt-2">
                                    {{ title }}
                                </h3>
                            </div>

                            <div class="float-right mt-1 mr-2"></div>
                        </div>
                        <div v-if="need_sync">
                            <div class="alert bg-danger rounded-0">
                                PERHATIAN! Data saat ini belum di sinkronasi,
                                sinkronasi sekarang.
                                <a href="#" @click="synData">Klik disini untuk sinkronisasi</a>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <div
                                class="form-group has-search float-left mt-4 ml-3 mb-3"
                                style="width: 96%"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control w-50"
                                    placeholder="Cari berdasarkan Nama..."
                                    @keyup="loadScores(1)"
                                    v-model="q"
                                />
                            </div>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nama</th>
                                        <th
                                            v-for="(
                                                subject, index
                                            ) in listSubjects"
                                            :key="index"
                                        >
                                            {{ subject }}
                                        </th>
                                        <th>Nilai Akhir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(score, index) in scores.data"
                                        :key="score.id"
                                    >
                                        <td style="width:5%;">
                                            {{
                                                index +
                                                1 +
                                                (scores.current_page * 10 - 10)
                                            }}
                                        </td>
                                        <td>{{ score.name }}</td>
                                        <td v-for="(score_per_subject, i) in score.score" :key="i.subject_id">
                                            {{ score_per_subject.score }}
                                        </td>
                                        <td>{{ score.final_score.toFixed(2) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->

                        <!-- end of modal -->

                        <div class="card-footer">
                            <nav aria-label="..." class="float-right">
                                <pagination
                                    :data="scores"
                                    @pagination-change-page="loadScores"
                                >
                                    <span slot="prev-nav">Sebelumnya</span>
                                    <span slot="next-nav">Selanjutnya </span>
                                </pagination>
                            </nav>
                        </div>
                        <!-- /.card-footer-->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import ButtonBack from "../../build-in/ButtonBack.vue";

export default {
    components: {
        ButtonBack,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: "Nilai",
                    active: false,
                    to: { name: 'myscore' },
                },
                {
                    text: "Try Out",
                    active: false,
                    to: { name: 'myscore-tryout' },
                },
            ],
            currentPage: 1,
            q: "",
            listSubjects: [],
            scores: {},
            title: "",
            need_sync: false
        };
    },
    methods: {
        loadScores(page = 1) {
            axios
                .get("/api/scores/tryout/" + this.$route.params.tryout_id, {
                    params: {
                        page,
                        search: this.q,
                    },
                })
                .then(({ data }) => {
                    if (this.breadcrumbs.length < 3) {
                        this.breadcrumbs.push({
                            text: data.data.detail.judul,
                        });

                        this.title = data.data.detail.judul
                    }

                    this.listSubjects = data.data.subjects.map((subject) => {
                        return subject.subjects_name;
                    });

                    this.scores = data.data.participant_score;
                    this.need_sync = data.data.need_sync
                });
        },
        synData() {
            this.$Progress.start();
            axios
                .post(`/api/scores/tryout/${this.$route.params.tryout_id}/sync`)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                });

            this.loadScores();
        },
    },
    created() {
        this.$Progress.start();
        this.loadScores();
        this.$Progress.finish();
    },
};
</script>
