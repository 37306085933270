<template>
  <section class="content">
    <div class="container-fluid">
      <template>
        <b-breadcrumb :items="items"></b-breadcrumb>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-color text-white">
              <div class="float-left mt-2">
                <h3 class="card-title font-weight-bold pt-2">List Kuis</h3>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <div
                class="form-group has-search float-left mt-5 ml-3 mb-3"
                style="width: 96%"
              >
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cari berdasarkan ID/Mapel..."
                  @keyup="getResults(1)"
                  v-model="q"
                />
              </div>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>ID Mapel</th>
                    <th>Mata Pelajaran Mapel</th>
                    <th>Jumlah Quis</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="subject in subjects.data" :key="subject.id">
                    <td>{{ subject.str_id }}</td>
                    <td>{{ subject.subjects_name }}</td>
                    <td>{{ subject.quiz_count }}</td>
                    <td>
                      <a
                        :href="'/manage/quizzes/' + subject.str_id"
                        class="text text-secondary text-underline"
                      >
                        <u>Lihat Detail</u>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <pagination
                class="d-flex justify-content-end mr-4"
                :data="subjects"
                @pagination-change-page="getResults"
              >
                <template #prev-nav>
                  <span>Sebelumnya</span>
                </template>
                <template #next-nav>
                  <span>Selanjutnya</span>
                </template>
              </pagination>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import pagination from "laravel-vue-pagination";

export default {
  components: {
    VueTagsInput,
    pagination,
  },
  data() {
    return {
      editmode: false,
      subjects: {},
      q: "",
      items: [
        {
          text: "Kuis",
          active: true,
        },
      ],
    };
  },
  methods: {
    getResults(page = 1) {
      this.$Progress.start();

      axios
        .get("/api/quiz?q=" + this.q + "&page=" + page)
        .then(({ data }) => (this.subjects = data.data));

      this.$Progress.finish();
    },
  },
  created() {
    this.$Progress.start();

    this.getResults(1);

    this.$Progress.finish();
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
};
</script>
