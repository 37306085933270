<template>
  <section class="content">
    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header header-color text-white">
                <div class="float-left mt-2">
                    <h3 class="card-title font-weight-bold pt-2">List Mata Pelajaran</h3>
                </div>

                <div class="card-tools">
                  <div class="float-right mt-1 mr-2">
                    <button type="button" class="btn btn-primary" @click="newModal">
                      <i class="fa fa-plus-circle"></i>
                      Buat Mata Pelajaran
                    </button>
                 </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <div class="form-group has-search float-left mt-5 ml-3 mb-3" style="width:96%;">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Mapel.." @keyup="getResults(1)" v-model="q">
                </div>

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID Mapel</th>
                      <th>Mata Pelajaran Mapel</th>
                      <th>Jumlah Soal</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="subject in subjects.data" :key="subject.id">

                      <td>{{subject.str_id}}</td>
                      <td>{{subject.subjects_name}}</td>

                      <td>{{subject.soal_count}}</td>
                      <td>

                        <a href="#" class="btn btn-sm btn-success mr-2" @click="editModal(subject)">
                            <i class="fa fa-edit"></i>
                        </a>

                        <a href="#" class="btn btn-sm btn-danger mr-2" @click="deleteSubject(subject.id)">
                            <i class="far fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination
                    class="d-flex justify-content-end mr-4"
                    :data="subjects"
                    @pagination-change-page="getResults"
                    >
                    <template #prev-nav>
                    <span>Sebelumnya</span>
                    </template>
                    <template #next-nav>
                    <span>Selanjutnya</span>
                    </template>
                </pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Form Buat Mata Pelajaran</h5>
                    <h5 class="modal-title" v-show="editmode">Form Edit Mata Pelajaran</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateSubjects() : createSubjects()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Mata Pelajaran</label>
                            <input v-model="form.subjects_name" type="text" name="subjects_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('subjects_name') }">
                            <has-error :form="form" field="subjects_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>ID Mata Pelajaran</label>
                            <input v-model="form.str_id" type="text" name="str_id"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('str_id') }">
                            <has-error :form="form" field="str_id"></has-error>
                        </div>
                        <div class="form-group">

                            <label>Sistem Penilaian</label>
                            <select class="form-control" v-model="form.scoring_system">
                              <option
                                  v-for="(sc,index) in scoring_systems" :key="index"
                                  :value="index"
                                  :selected="index == form.scoring_system">{{ sc }}</option>
                            </select>
                            <has-error :form="form" field="scoring_system"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import pagination from 'laravel-vue-pagination';

    export default {
      components: {
          VueTagsInput,
          pagination,
        },
        data () {
            return {
                editmode: false,
                subjects : {},
                q: '',
                form: new Form({
                    id : '',
                    str_id : '',
                    subjects_name: '',
                    scoring_system: '',
                }),
                scoring_systems: [],
                items: [
                {
                    text: 'Mata Pelajaran',
                    active: true
                }
              ],
            }
        },
        methods: {
          getResults(page = 1) {

              this.$Progress.start();

              axios.get('api/subjects?q='+this.q+'&page=' + page ).then(({ data }) => (this.subjects = data.data));

              this.$Progress.finish();
          },
          loadSubjets(){
              axios.get("api/subjects").then(({ data }) => (this.subjects = data.data));
          },
          loadScoringSystem(){
              axios.get("api/scoring-system").then(({ data }) => (this.scoring_systems = data.data));
          },
          editModal(subject){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              subject.scoring_system = subject.scoring_system.toLowerCase()
              this.form.fill(subject);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createSubjects(){
              this.$Progress.start();

              this.form.post('api/subjects')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadSubjets();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateSubjects(){
              this.$Progress.start();
              this.form.put('api/subjects/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadSubjets();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteSubject(id){
              Swal.fire({
                  title: 'Apakah kamu yakin?',
                  text: "Data yang dihapus tidak dapat dikembalikan!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Ya, hapus saja!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/subjects/'+id).then((data)=>{
                                if(data.data.success){
                                    Swal.fire(
                                    'Terhapus!',
                                    'Data berhasil dihapus',
                                    'success'
                                    );
                                    this.loadSubjets();
                                } else {
                                    Swal.fire(
                                    'Gagal Menghapus!',
                                    data.data.message,
                                    'warning'
                                    );
                                }
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {},
        created() {
            this.$Progress.start();

            this.loadSubjets();
            this.loadScoringSystem();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
