<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <!-- /.card-header -->
                        <div class="card-body">
                            <form @submit.prevent="updatePassword()">
                                <div class="pt-2">
                                    <div class="">
                                        <h4>Profil</h4>
                                    </div>
                                    <div class="row pt-2">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="name">Nama</label>
                                                <input
                                                    v-model="detail.name"
                                                    type="text"
                                                    class="form-control col-10"
                                                    id="name"
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-2">
                                    <div class="">
                                        <h4>Akun</h4>
                                    </div>
                                    <div class="row pt-2">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="id">ID Siswa</label>
                                                <input
                                                    v-model="detail.str_id"
                                                    type="text"
                                                    class="form-control col-10"
                                                    id="id"
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-2">
                                    <div class="">
                                        <h4>Ganti Password</h4>
                                    </div>
                                    <div class="row pt-2">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="password"
                                                    >Password Sekarang</label
                                                >
                                                <input
                                                    v-model="form.current_password"
                                                    type="password"
                                                    class="form-control col-10"
                                                    id="password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="password"
                                                    >Password Baru</label
                                                >
                                                <input
                                                    v-model="form.new_password"
                                                    type="password"
                                                    class="form-control col-10"
                                                    id="password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="password"
                                                    >Konfirmasi Password Baru</label
                                                >
                                                <input
                                                    v-model="form.confirm_password"
                                                    type="password"
                                                    class="form-control col-10"
                                                    id="password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <button
                                                type="submit"
                                                class="btn btn-primary"
                                            >
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
                </div>
                <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: new Form({
                current_password: "",
                new_password: "",
                confirm_password: ""
            }),
            detail: {},
        };
    },
    mounted() {
        console.log("Component mounted.");
    },
    methods: {
        getDetail(id) {
            axios
                .get(`/api/account/student/${id}`)
                .then(({ data }) => (this.detail = data.data));
        },
        updatePassword() {
            this.$Progress.start();
            this.form
                .post("api/change-password")
                .then((data) => {
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.current_password = "";
                    this.form.new_password = "";
                    this.form.confirm_password = "";

                    Toast.fire({
                        icon: "success",
                        title: data.data.message,
                    });
                })
                .catch((error) => {
                    this.$Progress.fail();

                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                    } else {
                    }
                });
        },
    },

    created() {
        // this.$Progress.start();

        // axios.get("api/profile").then(({ data }) => this.form.fill(data.data));

        // this.$Progress.finish();
        this.getDetail(window.user.id);
    },
};
</script>
