export default {
    methods: {
        $can(permissionName) {
            return window.user.ability.indexOf(permissionName) !== -1;
        },

        $isAdmin(){
            return window.user.type === 'admin';
        },

        $isStudent(){
            return window.user.type === 'student';
        },

        $isAdminOrUser(){
            if(window.user.type === 'user' || window.user.type === 'admin'){
                return true;
            }
        }
    },
};
