<template>
  <section class="content w-75">

    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a href="/manage/try-out"><i class="fa fa-arrow-left" aria-hidden="true"> </i> Kembali</a>
        </div>

        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header header-color">

                <div class="float-left mt-2">
                    <h3 class="card-title">{{stageTitle}}</h3>
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">


                    <template>
                    <div class="mt-4 mb-2 ml-3 mr-3">
                        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                            <span v-if="stage == -1">
                                <b-form-group id="input-group-1" label="Kelas*" label-for="input-1">
                                    <b-form-select
                                    id="input-1"
                                    v-model="form.kelas"
                                    :options="optionsKelas"
                                    v-on:change="setQuestSetting"
                                    required
                                    class="w-25"
                                    ></b-form-select>
                                </b-form-group>

                                <b-form-group
                                    label="Judul Try Out*"
                                    label-for="input-2"
                                    id="input-group-2"
                                >
                                    <b-form-input
                                    id="input-2"
                                    v-model="form.judul_tryout"
                                    placeholder="Tulis judul try out"
                                    required
                                    class="w-100"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    label="Tanggal*"
                                    label-for="input-3"
                                    id="input-group-3"
                                >
                                <input type="datetime-local" v-model="form.date" id="input-3" required class="form-control w-50" />
                                </b-form-group>

                                <b-form-group
                                    label="Waktu Pengerjaan*"
                                    label-for="input-4"
                                    id="input-group-4"
                                >
                                    <div class="form-inline">
                                    <b-form-input
                                    id="input-4"
                                    type="number"
                                    v-model="form.time"
                                    class="w-25 mr-2"
                                    min="0"
                                    ></b-form-input> <span>Menit</span>
                                    </div>
                                </b-form-group>
                                
                                <b-form-group id="input-group-1" label="Pengaturan Soal*" label-for="input-5">
                                    <b-form-select
                                    id="input-5"
                                    v-model="questSetting"
                                    :options="optionsQuestSetting"
                                    required
                                    class="w-25"
                                    v-on:change="setQuestSetting"
                                    ></b-form-select>
                                </b-form-group>

                                <table class="table table-borderless" style="width:65%;" v-if="showMapel">
                                <thead>
                                    <tr>
                                    <th scope="col" class="col-3">Mata Pelajaran</th>
                                    <th scope="col" class="col-3">Jumlah Soal*</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(data, index) in classMapel" :key="data.id">
                                        <td class="col-3">{{data.subjects_name}}</td>
                                        <td lass="col-3">
                                            <b-form-input
                                            :id="'input-mapel-'+index"
                                            type="number"
                                            v-model="form.mataPelajaran[data.id]"
                                            class="w-50"
                                            required
                                            ></b-form-input>
                                        </td>
                                    </tr>

                                </tbody>
                                </table>
                            </span>
                            <div class="container" style="padding:0;" v-if="stage > -1">
                                <div class="row border-bottom">
                                    <div class="col-12 pt-2 pb-2 ">
                                        <p>Pilih Soal</p>
                                    </div>
                                </div>
                                <div class="row border-bottom">
                                    <div class="col-6 pt-3 pb-3">
                                        <b>{{currentSubject}}</b>
                                    </div>
                                    <div class="col-6 text-right pt-3 pb-3">
                                        <span>{{currentQuestTotal}} soal</span>
                                    </div>
                                </div>
                                <div class="row border-bottom">
                                    <div class="col-12 pt-5 pb-3">
                                        <div
                                            class="form-group has-search"
                                        >
                                            <span
                                                class="fa fa-search form-control-feedback"
                                            ></span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Cari soal.."
                                                @keyup="loadQuest" 
                                                v-model="q"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-12" style="padding:0; margin:0;">      
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Soal</th>
                                                    <th>Pilih</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(key, index) in questions.data.data" :key="key.id"
                                                >
                                                    <td>
                                                        {{(index+1)+(currentPage*10-10)}}
                                                    </td>
                                                    <td>
                                                        <div v-html="maxChars(key.soal,60)" class="soal-img"></div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" 
                                                            type="checkbox" 
                                                            value=""
                                                            @click="check_uncheck(key.id)" 
                                                            :checked="checkSelectedStatus(key.id)">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <nav aria-label="..." class="float-right mr-3">
                                            <pagination
                                                :data="questions.data"
                                                @pagination-change-page="loadQuest"
                                            >
                                                <span slot="prev-nav">Sebelumnya</span>
                                                <span slot="next-nav">Selanjutnya </span>
                                            </pagination>
                                        </nav>
                                    </div>
                                    <div class="col-12">
                                        <p>Jumlah soal dipilih: {{currentTotalSelectedSoal}}</p>
                                    </div>
                                </div>
                            </div>
                                
                            <div class="container">
                            <div class="row">
                                <div class="col text-center pt-4 pb-4">
                                    <span v-if="stage == -1">
                                        <b-button class="btn-md btn-outline-dark btn-light" @click="onReset">Batal</b-button>
                                    </span>
                                    <span v-if="stage >= 0">
                                        <b-button  
                                        class="btn-md btn-outline-dark btn-light" 
                                        @click="backStage"
                                        >Kembali</b-button>
                                    </span>

                                    <b-button 
                                    variant="primary" 
                                    class="btn-md btn-primary"
                                    v-if="questSetting == 'tentukan_soal' 
                                    && form.kelas != null 
                                    && !stageFinished
                                    "
                                    @click="nextStage"
                                    >Selanjutnya</b-button>

                                    <span v-else>
                                        <b-button 
                                        variant="primary" 
                                        class="btn-md btn-primary"
                                        @click="onSubmit"
                                        >Buat Try Out</b-button>
                                    </span>



                                </div>
                            </div>
                            </div>

                        </b-form>

                    </div>
                    </template>




              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<script>
    import axios from 'axios';

    export default {
        data () {
            return {
              currentSubject:'Bank Soal Bahasa Indonesia',
              stageTitle: 'Buat Try Out',
              stage:-1,
              courses: {},
              q: '',
              subjectType : null,
              items: [
                    {
                        text: 'Try Out',
                        href: '/manage/try-out'
                    },
                    {
                        text: 'Buat Try Out',
                        active: true
                    }
                ],
                selectedKelas: null,
                optionsKelas: [
                    { value: null, text: 'Pilih Kelas'},
                ],
                optionsKelasType : [],
                classMapel: [],
                form: {
                    kelas: null,
                    judul_tryout: '',
                    date: null,
                    time: 0,
                    mataPelajaran: {},
                    selectedSoal: [],

                },
                show: true,
                showMapel: false,
                optionsQuestSetting: [
                    { value: null, text: 'Pilih Pengaturan'},
                    { value: 'acak_soal', text: 'Acak Soal'},
                    { value: 'tentukan_soal', text: 'Tentukan Soal'},
                ],
                questSetting: null,
                questions: [],
                currentPage: 1,
                currentQuestTotal: 0,
                idCurrentSubject: null,
                currentTotalSelectedSoal: 0,
                stageFinished: false,
            }
        },
        methods: {
            initialData(){
                axios.get('/api/tryout/create/view').then(({data}) => {
                    for(let idx in data){
                        this.optionsKelas.push({
                            value: data[idx].id,
                            text: data[idx].group_name
                        });
                    }
                });
            },
            setKelas(){
                axios.get('/api/tryout/kelas/mapel/'+this.form.kelas).then(({data}) => {
                    this.classMapel = []
                    this.form.mataPelajaran = {}

                    for(let idx in data){
                        this.classMapel.push({
                            id: data[idx].id,
                            subjects_name: data[idx].subjects_name
                        });

                        this.form.mataPelajaran[data[idx].id] = 0;
                        this.form.selectedSoal[data[idx].id] = {
                            id: data[idx].id,
                            selected: []
                        };
                    }
                });
            },
            setQuestSetting(){
                if(this.questSetting == 'acak_soal'){
                    this.setKelas();
                    this.showMapel = true
                }
                if(this.questSetting == 'tentukan_soal'){
                    this.setKelas();
                    this.showMapel = false
                }
      
            },
            nextStage(){
                let mapelLength = this.classMapel.length;
                let totalChecked = this.form.mataPelajaran[this.idCurrentSubject];

                if(mapelLength > 0 && this.stage < mapelLength-1){
                    this.stage += 1;
                    if(this.stage > 0 && totalChecked == 0){
                        this.stage -= 1;
                        Toast.fire({
                            icon: 'error',
                            title: 'Harus memilih setidaknya satu soal sebelum melanjutkan'
                        });
                    }else{
                        if(this.form.kelas != null && this.form.judul_tryout != '' && this.form.date != null && this.form.time != 0){
                            this.currentSubject = this.classMapel[this.stage].subjects_name
                            let subjectId = this.classMapel[this.stage].id
                            this.idCurrentSubject = subjectId
                            this.loadQuest();
                            this.stageTitle = 'Form Pilih Soal Try Out'
                            this.currentTotalSelectedSoal = this.form.selectedSoal[this.idCurrentSubject].selected.length
                        }else{
                            this.stage -= 1;
                            Toast.fire({
                                icon: 'error',
                                title: 'Anda harus melengkapi form sebelum melanjutkan'
                            });  
                        }

                    }
                }

                if(this.stage == mapelLength-1){
                    this.stageFinished = true;
                }
            },
            backStage(){
                this.stage -= 1;

                if(this.stage >= 0){
                    this.currentSubject = this.classMapel[this.stage].subjects_name
                    let subjectId = this.classMapel[this.stage].id
                    this.idCurrentSubject = subjectId
                    this.loadQuest();
                    this.currentTotalSelectedSoal = this.form.selectedSoal[this.idCurrentSubject].selected.length
                }
                
                if(this.stage == -1){
                    this.stageTitle= 'Buat Try Out'
                }

                this.stageFinished = false;

            },
            loadQuest(page=1){
              let idSubject = this.idCurrentSubject
              if(this.q){
                axios.get('/api/questions/'+idSubject+'?page=' + page+'&q='+this.q).then(({data}) => {
                    this.questions = data
                    this.currentQuestTotal = data.data.total
                });
              }else{
                axios.get('/api/questions/'+idSubject+'?page=' + page).then(({data}) => {
                    this.questions = data
                     this.currentQuestTotal = data.data.total
                });
              }
            },
          checkSelectedStatus(idQuest){
            let selectedQuest = this.form.selectedSoal[this.idCurrentSubject].selected
            if(selectedQuest.indexOf(idQuest) != -1){
                return true;
            }

             return false;

          },
          check_uncheck(idQuest){
            let selectedQuest = this.form.selectedSoal[this.idCurrentSubject].selected
            let idx = selectedQuest.indexOf(idQuest)

            if(idx != -1){
                selectedQuest.splice(idx, 1)
                this.form.mataPelajaran[this.idCurrentSubject] -= 1;
            }else{
                selectedQuest.push(idQuest)
                this.form.mataPelajaran[this.idCurrentSubject] += 1;
            }
            this.currentTotalSelectedSoal = this.form.selectedSoal[this.idCurrentSubject].selected.length

          },
          loadQuestion(page=1){
              this.currentPage = page;
              this.$Progress.start();

              if(this.q){
                axios.get('/api/questions/'+this.$route.params.id+'?page=' + page+'&q='+this.q).then(({data}) => (this.questions = data));
              }else{
                axios.get('/api/questions/'+this.$route.params.id+'?page=' + page).then(({data}) => (this.questions = data));
              }

              this.$Progress.finish();
          },
          setRemovedId(removedId){
            if(removedId){
              this.soalRemovedId = removedId
            }
          },
          removeSoal(){

            if(this.soalRemovedId){
              //hit remove api
              axios.delete('/api/questions/'+this.soalRemovedId).then(({data}) => alert(data.status))

              //reload data
              this.loadQuestion();
            }

          },
          strippedHtml(text) {
              let regex = /<\/?(?!img)\w*\b[^>]*>/ig;

              return '<p>'+text.replace(regex, "")+'</img></p>';
          },
          maxChars(str, max){
              var tags = 0,
              sQuotes = 0,
              dQuotes = 0,
              char,
              count = 0,
              result = [];
          for (var i = 0, len = str.length; i < len; ++i) {
            char = str.charAt(i);
            switch(char) {
              case '<':
                if (!sQuotes && !dQuotes) {
                  ++tags;
                  result.push(char);
                  continue;
                }
                break;
              case '>':
                if (!sQuotes && !dQuotes) {
                  --tags;
                  result.push(char);
                  continue;
                }
                break;
              case "'":
                if (tags && !dQuotes)
                  sQuotes = !sQuotes;
                break;
              case '"':
                if (tags && !sQuotes)
                  dQuotes = !dQuotes;
                break;
            }
            if (tags) {
              result.push(char);
            } else {
              if (++count < max)
                result.push(char);
            }
          }
          let r = result.join('');

          return r+'';
          },
            onSubmit(event) {
                event.preventDefault()
                let isValidated = true;
                let totalChecked = this.form.mataPelajaran[this.idCurrentSubject];

                if(this.questSetting == 'tentukan_soal' && this.stageFinished && totalChecked == 0){
                    Toast.fire({
                        icon: 'error',
                        title: 'Harus memilih setidaknya satu soal sebelum melanjutkan'
                    });
                }else{
                    if(confirm('Apakah Anda yakin buat tryout sekarang?') == true) {
                        this.form.questSetting = this.questSetting
                        axios.post('/api/tryout', this.form).then(({data}) => {
                            if(data.status){
                                Toast.fire({
                                    icon: 'success',
                                    title: data.message
                                });
                                window.location.href = '/manage/try-out'
                            }else{
                                Toast.fire({
                                    icon: 'error',
                                    title: data.message
                                });
                            }

                        })
                    }

                }

            },
            onReset(event) {
                event.preventDefault()
                    this.form.kelas = null
                    this.form.judul_tryout = ''
                    this.form.date = null
                    this.form.time = 0
                    this.form.mataPelajaran = {}
                    this.classMapel = []
                    this.showMapel = false
                    this.currentQuestTotal = 0
                    this.idCurrentSubject = null
                    this.currentTotalSelectedSoal = 0
                    this.stageFinished = false
            }


        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
