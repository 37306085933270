<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->
            <ButtonBack />
            <div class="row mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <div class="float-left mt-2">
                                <h3 class="card-title font-weight-bold pt-2">
                                    Nilai Try Out
                                </h3>
                            </div>

                            <div class="float-right mt-1 mr-2"></div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <h4 class="mt-4 ml-3 mb-1">Filter</h4>
                            <form class="form-inline mb-4 ml-3">
                                <b-form-select
                                    id="input-1"
                                    v-model="filterKelas"
                                    :options="optionsKelas"
                                    class="w-25 mr-5"
                                    disabled="true"
                                ></b-form-select>

                                <input
                                    type="date"
                                    v-model="filterTgl"
                                    @change="loadTryout()"
                                    class="form-control w-50"
                                />
                            </form>

                            <div
                                class="form-group has-search float-left ml-3 mb-3"
                                style="width: 250px"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari berdasarkan judul.."
                                    @keyup="loadTryout(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Judul Try Out</th>
                                        <th>Kelas</th>
                                        <th>Tanggal</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(key, index) in tryouts.data"
                                        :key="key.id"
                                    >
                                        <td>
                                            {{
                                                index +
                                                1 +
                                                (currentPage * 10 - 10)
                                            }}
                                        </td>
                                        <td>{{ key.judul }}</td>
                                        <td>{{ key.kelas.group_name }}</td>
                                        <td>{{ key.tanggal }}</td>
                                        <td>
                                            <router-link
                                                :to="{
                                                    name: 'myscore-tryout-detail',
                                                    params: {
                                                        tryout_id: key.id,
                                                    },
                                                }"
                                            >
                                                {{ "Lihat Detail" }}
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->

                        <!-- end of modal -->

                        <div class="card-footer">
                            <nav aria-label="..." class="float-right">
                                <pagination
                                    :data="tryouts"
                                    @pagination-change-page="loadTryout"
                                >
                                    <span slot="prev-nav">Sebelumnya</span>
                                    <span slot="next-nav">Selanjutnya </span>
                                </pagination>
                            </nav>
                        </div>
                        <!-- /.card-footer-->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import ButtonBack from "../../build-in/ButtonBack.vue";

export default {
    components: {
        ButtonBack,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: "Nilai",
                    active: false,
                    to: { name: 'myscore' },
                },
                {
                    text: "Try Out",
                    active: true,
                },
            ],
            tryouts: {},
            q: "",
            filterKelas: window.group,
            filterTgl: "",
            tryoutRemovedId: "",
            currentPage: 1,
            optionsKelas: [{ value: "", text: "Pilih Kelas" }],
        };
    },
    methods: {
        loadTryout(page = 1) {
            axios
                .get(
                    "/api/tryout?page=" +
                        page +
                        "&q=" +
                        this.q +
                        "&kelas=" +
                        this.filterKelas +
                        "&tgl=" +
                        this.filterTgl
                )
                .then(({ data }) => {
                    this.tryouts = data;
                    if (this.optionsKelas.length == 1) {
                        for (let idx in data.kelas) {
                            this.optionsKelas.push({
                                value: data.kelas[idx].id,
                                text: data.kelas[idx].group_name,
                            });
                        }
                    }
                });

        },
    },
    created() {
        this.$Progress.start();
        this.loadTryout();
        this.$Progress.finish();
    },
};
</script>
