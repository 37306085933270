<template>
    <section class="content">
        <div class="container-fluid">
            <template>
                <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
            </template>
            <ButtonBack />
            <div class="row mt-3">
                <div class="col-8">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <!-- <div class="float-left mt-2"> -->
                            <h3 class="card-title font-weight-bold pt-2">
                                {{ detail.subject_name }} -
                                {{ detail.quiz_name }}
                            </h3>
                            <div class="card-tools pr-2 pt-2">
                                Total Peserta : {{ scores.total }}
                            </div>
                            <!-- </div> -->
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <div
                                class="form-group has-search float-left mt-4 ml-3 mb-3"
                                style="width: 96%"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari berdasarkan Nama..."
                                    @keyup="getResults(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nama</th>
                                        <th>Nilai Akhir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(score, index) in scores.data"
                                        :key="index"
                                    >
                                        <td>
                                            {{
                                                index +
                                                1 +
                                                (scores.current_page * 10 - 10)
                                            }}
                                        </td>
                                        <td>{{ score.name }}</td>
                                        <td>{{ score.total_score }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="scores"
                                @pagination-change-page="getResults"
                                class="d-flex justify-content-end mr-4"
                            >
                                <template #prev-nav>
                                    <span>Sebelumnya</span>
                                </template>
                                <template #next-nav>
                                    <span>Selanjutnya</span>
                                </template>
                            </pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import pagination from "laravel-vue-pagination";
import ButtonBack from "../../build-in/ButtonBack";

export default {
    components: {
        ButtonBack,
        VueTagsInput,
        pagination,
    },
    data() {
        return {
            editmode: false,
            scores: {},
            detail: {},
            q: "",
            breadcrumbs: [
                {
                    text: "Nilai",
                    active: false,
                    to: { name: "list-score" },
                },
                {
                    text: "Kuis",
                    active: false,
                    to: { name: "list-score-quizzes" },
                },
            ],
        };
    },
    methods: {
        getResults(page = 1) {
            axios
                .get(`/api/scores/quiz`, {
                    params: {
                        quiz_id: this.$route.params.quiz_id,
                        subject_id: this.$route.params.subject_id,
                        page,
                        search: this.q,
                    },
                })
                .then(({ data }) => {
                    this.scores = data.data.participant;
                    this.detail = data.data.detail;
                    if (this.breadcrumbs.length < 3) {
                        this.breadcrumbs.push({
                            text: data.data.detail.subject_name,
                            to: { name: 'list-score-quiz', params: { id: data.data.detail.subject_id } },
                        });
                        this.breadcrumbs.push({
                            text: data.data.detail.quiz_name,
                        });
                    }
                });
        },
    },
    created() {
        this.$Progress.start();

        this.getResults(1);

        this.$Progress.finish();
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
};
</script>
