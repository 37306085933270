<template>
  <section class="content w-75">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <h5 class="mb-4">List Try Out</h5>
            <div class="card">
              <div class="card-header bg-info">

                <div class="float-left mt-2">
                    <h3 class="card-title">Try Out</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">

                <div class="container">
                    <div class="row">

                        <div class="col-md-6 p-0" v-for="(key, index) in tryouts" :key="key.id">
                            <div :class="{'border-bottom border-right ': index % 2 === 0, 'border-bottom border-left': index % 2 !== 0 }">
                                <div class="tryout-card p-3 pt-4">
                                  <p><h6 class="mb-2"><b>{{key.title}}</b></h6></p>
                                  <p><i class="fas fa-calendar-alt mr-2" style="font-size:14px;"></i> {{key.date}}</p>
                                  <p><i class="fas fa-book-open mr-2" style="font-size:14px;"></i> {{key.mapel}}</p>
                                  <p><i class="fas fa-file-pdf mr-2" style="font-size:14px;"></i> {{key.totalSoal}} Soal</p>
                                  <p><i class="fas fa-hourglass-start mr-2" style="font-size:14px;"></i> {{key.time}} Menit</p>
                                  <div class="text-center">
                                    <a :href="'/siswa/try-out/pre-exam/'+key.id" :class="[key.statusColor]" class="btn btn-md mb-3" style="min-width:130px;"
                                    v-if="key.status == 'Mulai Try Out'"
                                    >
                                      {{key.status}}
                                    </a>
                                    <b-button type="submit" :variant="[key.statusColor]" class="btn-md mb-3" style="min-width:130px;"
                                    v-else
                                    >
                                      {{key.status}}
                                    </b-button>
                                    <br />
                                    <a :href="'/siswa/try-out/detail/'+key.id" v-if="key.detail">Lihat Detail</a>
                                  </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

              </div>
              <!-- /.card-body -->

              <div class="card-footer">

              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<style scoped>
.tryout-card{
  min-height: 330px;
}
.tryout-card p{
  max-width:80%;
  font-size:12px;
}
</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              tryouts: [],

            }
        },
        methods: {
            initialData(){
                  axios.get('/api/siswa/try-out/list').then(({data}) => {
                  this.tryouts = data.data

                })
            },
        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
