<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                Akun
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">
                                Manajemen Akun
                            </h3>
                            <div class="card-tools">
                                <a
                                    href="javascript:void(0)"
                                    @click="
                                        $router.push('/manage/account/create')
                                    "
                                    class="btn btn-primary"
                                    v-if="$can('create-account')"
                                >
                                    <i class="fas fa-plus-circle"></i>
                                    Buat Akun
                                </a>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="row pt-3 px-3">
                                <div class="col">
                                    <nav class="nav nav-pills">
                                        <a
                                            class="nav-link"
                                            :class="changeToStudent"
                                            @click="
                                                changeTypeOfAccount('student')
                                            "
                                            href="#"
                                            >Table Siswa</a
                                        >
                                        <a
                                            class="nav-link"
                                            :class="changeToTeacher"
                                            @click="
                                                changeTypeOfAccount('teacher')
                                            "
                                            href="#"
                                            >Table Pengajar</a
                                        >
                                    </nav>
                                </div>
                                <div class="col">
                                    <form
                                        @submit.prevent="searchForm()"
                                        class="input-group mb-3"
                                    >
                                        <div class="input-group-prepend">
                                            <button
                                                type="submit"
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            name="search"
                                            class="form-control"
                                            v-model="searchKeyword"
                                            placeholder="Cari berdasarkan ID"
                                        />
                                    </form>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 15%">ID</th>
                                            <th>Nama</th>
                                            <th>Status Akun</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="account in accounts.data"
                                            :key="account.id"
                                        >
                                            <td>{{ account.str_id }}</td>
                                            <td>{{ account.name }}</td>
                                            <td>
                                                <span
                                                    class="text-danger"
                                                    v-if="account.is_trashed"
                                                    >Tidak Aktif</span
                                                >
                                                <span
                                                    class="text-success"
                                                    v-if="!account.is_trashed"
                                                    >Aktif</span
                                                >
                                            </td>
                                            <td>
                                                <b-button
                                                    variant="success"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="
                                                        $router.push({
                                                            path: `/manage/account/edit/${account.id}`,
                                                            query: {
                                                                type: `${account.type}`,
                                                            },
                                                        })
                                                    "
                                                    v-if="
                                                        !account.is_trashed &&
                                                        $can('edit-account')
                                                    "
                                                >
                                                    <i class="fas fa-pen"></i>
                                                </b-button>
                                                <!-- <b-button
                                                    variant="primary"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="
                                                        $router.push({
                                                            path: `/manage/account/${account.id}`,
                                                            query: { type: `${account.type}` }
                                                        })
                                                    "
                                                    v-if="!account.is_trashed"
                                                >
                                                    <i class="fas fa-eye"></i>
                                                </b-button> -->
                                                <b-button
                                                    variant="danger"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="
                                                        deleteAccount(account)
                                                    "
                                                    v-if="
                                                        !account.is_trashed &&
                                                        $can('delete-account')
                                                    "
                                                >
                                                    <i class="fas fa-trash"></i>
                                                </b-button>
                                                <b-button
                                                    variant="primary"
                                                    size="sm"
                                                    class="mr-2"
                                                    v-if="
                                                        account.is_trashed &&
                                                        $can('delete-account')
                                                    "
                                                    @click="
                                                        restoreAccount(account)
                                                    "
                                                >
                                                    <i class="fas fa-undo"></i>
                                                </b-button>
                                            </td>
                                        </tr>
                                        <tr v-show="isEmpty">
                                            <td colspan="4" align="center">
                                                Belum ada data
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-end mr-4">
                                <pagination
                                    :data="accounts"
                                    @pagination-change-page="getResultsPaginate"
                                >
                                    <template #prev-nav>
                                        <span>Sebelumnya</span>
                                    </template>
                                    <template #next-nav>
                                        <span>Selanjutnya</span>
                                    </template>
                                </pagination>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import pagination from "laravel-vue-pagination";

export default {
    components: {
        pagination,
    },
    data() {
        return {
            typeOfAccount: "student",
            accounts: {
                data: {}
            },
            searchKeyword: "",
        };
    },
    methods: {
        loadAccounts() {
            axios
                .get("/api/accounts", {
                    params: {
                        type: this.typeOfAccount,
                        search: this.searchKeyword,
                    },
                })
                .then(({ data }) => (this.accounts = data.data));
        },
        getResultsPaginate(page = 1) {
            this.$Progress.start();

            axios
                .get("/api/accounts", {
                    params: {
                        type: this.typeOfAccount,
                        search: this.searchKeyword,
                        page: page,
                    },
                })
                .then(({ data }) => (this.accounts = data.data));

            this.$Progress.finish();
        },
        detailAccount() {},
        editAccount() {},
        deleteAccount(item) {
            this.$Progress.start();
            Swal.fire({
                title: "Hapus akun?",
                html: `Anda ingin menghapus akun <b>${item.str_id}</b>. Akun akan hilang dari sistem.`,
                showCancelButton: true,
                confirmButtonColor: "#e6e3e3",
                cancelButtonColor: "#d33",
                confirmButtonText: "Hapus",
                cancelButtonText: "Batal",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .delete("/api/accounts/" + item.id, {
                            data: {
                                type: item.type,
                            },
                        })
                        .then((data) => {
                            Toast.fire({
                                icon: "success",
                                title: data.data.message,
                            });
                            this.$Progress.finish();
                            this.loadAccounts();
                        })
                        .catch((data) => {
                            console.log(data);
                        });
                }
            });
        },
        changeTypeOfAccount(type) {
            this.typeOfAccount = type;
            this.searchKeyword = "";
            this.loadAccounts();
        },
        searchForm() {
            this.loadAccounts();
        },
        restoreAccount(item) {
            this.$Progress.start();
            Swal.fire({
                title: "Restore akun?",
                html: `Anda ingin mengembalikan akun <b>${item.str_id}</b>?`,
                showCancelButton: true,
                confirmButtonColor: "#e6e3e3",
                cancelButtonColor: "#d33",
                confirmButtonText: "Restore",
                cancelButtonText: "Batal",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .put(`/api/${item.type}/${item.id}/restore`)
                        .then((data) => {
                            Toast.fire({
                                icon: "success",
                                title: data.data.message,
                            });
                            this.$Progress.finish();
                            this.loadAccounts();
                        })
                        .catch((data) => {
                            console.log(data);
                        });
                }
            });
        },
    },
    created() {
        this.typeOfAccount = this.$route.query.type ?? "student";
        this.loadAccounts();
    },
    computed: {
        changeToStudent() {
            return {
                "border-bottom border-info rounded-0":
                    this.typeOfAccount == "student",
            };
        },
        changeToTeacher() {
            return {
                "border-bottom border-info rounded-0":
                    this.typeOfAccount == "teacher",
            };
        },
        isEmpty(){
            return this.accounts.data.length == 0 ? true : false
        },
    },
};
</script>
