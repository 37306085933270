<template>
  <section class="content w-75">

    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a :href="'/questions/'+this.questionId"><i class="fa fa-arrow-left" aria-hidden="true"> </i> Kembali</a>
        </div>

        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header bg-info">

                <div class="float-left mt-2">
                    <h3 class="card-title">Detail Soal</h3>
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                
                    <template>
                    <div class="mt-4 mb-2 ml-3 mr-3">
                        <b-form v-if="show">

                            <b-form-group id="input-group-1" label="Mata Pelajaran*" label-for="input-1">
                                <b-form-select
                                id="input-1"
                                :options="optionsMapel"
                                v-model="selectedMapel"
                                disabled
                                class="w-25"
                                ></b-form-select>
                            </b-form-group>

                            <b-form-group id="input-group-2" label="Soal*" label-for="input-2">
                                <vue-editor
                                id="editor"
                                v-model="form.soal"
                                disabled
                                >
                                </vue-editor>
                            </b-form-group>
                            <b-form-group
                                label="Opsi*"
                                label-for="input-3"
                                id="input-group-3"
                            >
                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-3"
                                        v-model="form.opsi1"
                                        placeholder="Tulis opsi a"
                                        class="w-100"
                                        disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file v-model="form.image_opsi1" plain disabled></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi1" target="_blank">{{this.form.image_opsi1}}</a>
                                    </div>                               
                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-4"
                                        v-model="form.opsi2"
                                        placeholder="Tulis opsi b"
                                        class="w-100"
                                        disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file v-model="form.image_opsi2" plain disabled></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi2" target="_blank">{{this.form.image_opsi2}}</a>
                                    </div>
                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-5"
                                        v-model="form.opsi3"
                                        placeholder="Tulis opsi c"
                                        class="w-100"
                                        disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file v-model="form.image_opsi3" plain disabled></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi3" target="_blank">{{this.form.image_opsi3}}</a>
                                    </div>
                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-6"
                                        v-model="form.opsi4"
                                        placeholder="Tulis opsi d"
                                        class="w-100"
                                        disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file v-model="form.image_opsi4" plain disabled></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi4" target="_blank">{{this.form.image_opsi4}}</a>
                                    </div>
                                </div>

                                <div class='form-inline mb-2'>
                                    <div class="col-8">
                                        <b-form-input
                                        id="input-7"
                                        v-model="form.opsi5"
                                        placeholder="Tulis opsi e"
                                        class="w-100"
                                        disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-4">
                                        <b-form-file v-model="form.image_opsi5" plain disabled></b-form-file>
                                        <a :href="this.loc+'/storage/images/'+this.form.image_opsi5" target="_blank">{{this.form.image_opsi5}}</a>
                                    </div>
                                    
                                </div>
                            </b-form-group>

                            <!-- subject type jawaban benar -->
                            <div v-if="subjectType == 'jawaban_benar'">
                                <b-form-group id="input-group-4" label="Kunci Jawaban*" label-for="input-7">
                                    <b-form-select
                                    id="input-8"
                                    v-model="selectedKunciJawaban"
                                    :options="optionsKunciJawaban"
                                    disabled
                                    class="w-25"
                                    ></b-form-select>
                                </b-form-group>

                                <b-form-group id="input-group-5" label="Pembahasan" label-for="input-9">
                                    <vue-editor
                                    id="editor2"
                                    v-model="form.pembahasan"
                                    disabled
                                    >
                                    </vue-editor>
                                </b-form-group>
                            </div>

                            <!-- subject type point -->
                            <div v-if="subjectType == 'point'">
                                <b-form-group id="input-group-6" label="Point Jawaban*" label-for="input-10">
                                    <table class="table table-borderless w-50">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="col-3">Opsi</th>
                                        <th scope="col" class="col-3">Point</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="col-3">A</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi1"
                                                class="w-50"
                                                disabled
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">B</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi2"
                                                class="w-50"
                                                disabled
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">C</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi3"
                                                class="w-50"
                                                disabled
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">D</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi4"
                                                class="w-50"
                                                disabled
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="col-3">E</td>
                                            <td class="col-3">
                                                <b-form-input
                                                id="input-9"
                                                type="number"
                                                v-model="form.point_opsi5"
                                                class="w-50"
                                                disabled
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>                        
                                </b-form-group>
                            </div>

                        </b-form>

                    </div>
                    </template>
        

   
              
              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>
<style scoped>
  #input-group-3 .form-control {
    border:none;
    border-bottom: 1px solid #1890ff;
    padding: 5px 10px;
    outline: none;
    margin-bottom: 15px;
   }
    #input-group-3 .form-control:focus{
    border-color: #ced4da;
    box-shadow: none;
    }
</style>
<script>
    import { VueEditor } from "vue2-editor";

    export default {
        components: {
            VueEditor
        },
        data () {
            return {
              loc : location.host,
              subjectType : '',
              questionId : null,
              q: '',
              items: [
                    {
                        text: 'Soal',
                        href: 'questions'
                    },
                    {
                        text: 'Buat Soal',
                        active: true
                    }
                ],
                selected: null,
                form: {
                    soal: '',
                    opsi1: '',
                    opsi2: '',
                    opsi3: '',
                    opsi4: '',
                    opsi5: '',
                    image_opsi1: '',
                    image_opsi2: '',
                    image_opsi3: '',
                    image_opsi4: '',
                    image_opsi5: '',
                    point_opsi1: '',
                    point_opsi2: '',
                    point_opsi3: '',
                    point_opsi4: '',
                    point_opsi5: '',
                    pembahasan: ''

                },
                optionsMapel: [],
                selectedMapel: null,
                optionsKunciJawaban: [],
                selectedKunciJawaban: null,
                show: true,
            }
        },
        methods: {
            getData(){
                axios.get('/api/questions/view/'+this.$route.params.id).then(({data}) => {
                //question ID
                this.questionId = data.subject_id
                //subject   
                this.optionsMapel.push({value: null, text : data.subject})
                //subject type
                this.subjectType = data.subject_type
                //soal
                this.form.soal = data.data.soal
                //Jawaban
                let opsiArr = data.data.opsi
                for(let opsiIdx in opsiArr){
                    if(opsiArr[opsiIdx].opsi_name == 'A'){
                        this.form.opsi1 = opsiArr[opsiIdx].keterangan_opsi
                        this.form.image_opsi1 = opsiArr[opsiIdx].gambar_opsi
                    }
                    if(opsiArr[opsiIdx].opsi_name == 'B'){
                        this.form.opsi2 = opsiArr[opsiIdx].keterangan_opsi
                        this.form.image_opsi2 = opsiArr[opsiIdx].gambar_opsi
                    }
                    if(opsiArr[opsiIdx].opsi_name == 'C'){
                        this.form.opsi3 = opsiArr[opsiIdx].keterangan_opsi
                        this.form.image_opsi3 = opsiArr[opsiIdx].gambar_opsi
                    }
                    if(opsiArr[opsiIdx].opsi_name == 'D'){
                        this.form.opsi4 = opsiArr[opsiIdx].keterangan_opsi
                        this.form.image_opsi4 = opsiArr[opsiIdx].gambar_opsi
                    }
                    if(opsiArr[opsiIdx].opsi_name == 'E'){
                        this.form.opsi5 = opsiArr[opsiIdx].keterangan_opsi
                        this.form.image_opsi5 = opsiArr[opsiIdx].gambar_opsi
                    }       
                }

                //Kunci jawaban
                if(this.subjectType == 'jawaban_benar'){
                    for(let opsiIdx in opsiArr){
                        if(opsiArr[opsiIdx].id == data.data.kunci_jawaban){
                            this.optionsKunciJawaban.push({value: null, text : opsiArr[opsiIdx].opsi_name})
                        }
                    }
                    //pembahasan
                    this.form.pembahasan = data.data.pembahasan
                }

                if(this.subjectType == 'point'){
                    
                    for(let opsiIdx in opsiArr){
                        if(opsiArr[opsiIdx].opsi_name == 'A'){
                            this.form.point_opsi1 = opsiArr[opsiIdx].point
                        }
                        if(opsiArr[opsiIdx].opsi_name == 'B'){
                            this.form.point_opsi2 = opsiArr[opsiIdx].point
                        }
                        if(opsiArr[opsiIdx].opsi_name == 'C'){
                            this.form.point_opsi3 = opsiArr[opsiIdx].point
                        }
                        if(opsiArr[opsiIdx].opsi_name == 'D'){
                            this.form.point_opsi4 = opsiArr[opsiIdx].point
                        }
                        if(opsiArr[opsiIdx].opsi_name == 'E'){
                            this.form.point_opsi5 = opsiArr[opsiIdx].point
                        }
                    }   
                }


            })

            },  
            
        },
        mounted() {
            
        },
        created() {
            //location
            let host = location.host;
            if(!host.includes("http://") && !host.includes("https://")){
                this.loc = 'http://'+host
            }
            this.$Progress.start();
            this.getData();
            this.$Progress.finish();
        }
    }
</script>
