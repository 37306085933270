<template>
  <section class="content w-100 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3><b>{{title}}</b></h3>
          <p>Durasi : {{durasi}} Menit</p>
          <p>Jumlah : {{jumlah_soal}} Soal</p>
        </div>
      </div>
      <div class="row">
        <img :src="bgImg" style="max-width:100%;"/>
      </div>
      <div class="row">
        <div class="col-12 text-center">
            <p>Test dapat dimulai dalam:  <Countdown :date="end" @onFinish="finish()" v-if="end != 0"></Countdown></p>
            <a :href="'/siswa/try-out/exam/'+this.$route.params.id" class="btn btn-md bg-primary" :class="{ 'disabled' : isDisabled }" id="start" :disabled="isDisabled">Mulai Test</a>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>

</style>
<script>
import axios from 'axios';
import Countdown from '../countdown/Countdown';

    export default {
      components: {
        Countdown
      },
        data () {
            return {
                title: '',
                durasi: 0,
                jumlah_soal: 0,
                bgImg: '',
                isDisabled: true,
                end: 0
            }
        },
        methods: {
            finish(){
              this.isDisabled = false
            },
            initial(){
                axios.get('/api/siswa/tryout/pre-exam/'+this.$route.params.id).then(({data}) => {
                   if(data.status == false){
                      if(data.finished_tryout){
                        window.location = '/siswa/try-out/detail/'+this.$route.params.id
                      }else{
                        window.location = '/siswa/try-out/list'
                      }
                   }

                    this.title = data.title
                    this.durasi = Math.floor(data.durasi)
                    this.jumlah_soal = data.jumlah_soal
                    this.end = data.count_down

                });
                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }

                this.bgImg = loc+'/storage/images/Petunjuk Pengerjaan Soal.png';


            },

        },
        created() {
            this.$Progress.start();
            this.initial();
            this.$Progress.finish();
        }
    }
</script>
