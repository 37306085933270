<template>
  <section class="content w-100">
    <div class="container">
      <div class="row mb-3">
        <div class="col-6">
          <h4><b>{{title}}</b></h4>
        </div>
        <div class="col-6">
         <b>Nilai: {{quizPembahasan.score}}/{{quizPembahasan.maxScore}}</b>
        </div>
      </div>
      <div class="row">

        <div class="col-12"><h5><b>{{quizPembahasan.subject.title}}</b></h5></div>

        <div class="col-8" v-for="(quest, index) in quizPembahasan.subject.quests" :key="quest.id_soal">
          <div class="soal mb-2">
           <span class="number">{{index+1}}. </span> <span class="content" v-html="quest.soal.soal"></span>
          </div>

          <div v-for="option in quest.soal.opsi" :key="option.id">
            <div class="answers form-check rounded"  style="display:inline-block;" :class="[option.selected == true ? 'border border-success' : '']">
              <input class="form-check-input" type="radio" :value="'{{option.option_name}}'"
              :name="'jawaban'+quest.soal.id"
              :checked="option.selected"
              disabled
              >
              <p>{{option.opsi_name}}. {{option.keterangan_opsi}}</p>
              <p v-if="option.gambar_opsi != ''">
                <img :src="host+option.gambar_opsi" style="max-width:100%;">
              </p>
            </div>

            <span v-if="quizPembahasan.subject.type == 'jawaban_benar' && option.correct == true" >
              <i class="fa fa-check text-success" aria-hidden="true" style="font-size:18px;"></i>
            </span>
            <span v-if="quizPembahasan.subject.type == 'jawaban_benar' && option.selected == true && option.correct == false">
              <i class="fa fa-times text-danger" style="font-size:18px;"></i>
            </span>
            <span v-if="quizPembahasan.subject.type == 'point' ">
              {{option.option_point}}
            </span>

          </div>
          <div class="mt-4 mb-4">
            <p><b>Kunci Jawaban: {{quest.soal.jawaban_benar.opsi_name}}</b></p>
            <p><b>Pembahasan</b></p>
            <p class="pembahasan-img" v-html="quest.soal.pembahasan"></p>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 text-center mb-4 mt-4">
            <a :href="'/siswa/quiz/detail/'+this.$route.params.id" class="btn btn-md btn-primary">Kembali ke Keterangan</a>
          </div>
      </div>
    </div>
  </section>
</template>
<style>
  .soal span{
    display: inline;
  }
  .soal span :nth-last-child(1){
    display: inline;
  }
  .soal img{
    max-width:90%;
  }
  .pembahasan-img img{
    max-width:90%;
  }
</style>
<style scoped>
  .answers{
    background-color: white;
    margin-left:20px;
    max-width:85%;
    min-width:85%;
    padding:20px 20px 5px 35px;
    margin-bottom:13px;
  }
  .finish-button{
    margin-top:40px;
  }
  .question-boxes{
    display:inline-block;
    margin-right: -20px;
    margin-bottom: -30px;
  }
  .box{
    padding:3px;
    min-height:30px;
    min-width:28px;
    width:28px;
    height:30px;
    font-size:14px;
    cursor:pointer;
  }
  .question-box{
    min-height:50px;
    padding:12px;
  }
  .question-navigation{
    background-color: white;
    min-height:500px;
    margin-bottom:100px;
  }
</style>
<script>
import axios from 'axios';

    export default {
        data () {
            return {
              host: '',
              quizPembahasan: {},
            }
        },
        methods: {
          initialData(){
                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }
                this.host = loc+'/storage/images/'

                axios.get('/api/siswa/quiz-pembahasan/'+this.$route.params.id).then(({data}) => {
                    this.quizPembahasan = data.data
                    console.log(this.quizPembahasan)
                    for (let index = 0; index < this.quizPembahasan.subject.quests.length; index++) {
                        const element = this.quizPembahasan.subject.quests[index];
                        for (let j = 0; j < element.soal.opsi.length; j++) {
                            if ((element.id_soal in this.quizPembahasan.answer) && this.quizPembahasan.answer[element.id_soal] === element.soal.opsi[j].id) {
                                this.quizPembahasan.subject.quests[index].soal.opsi[j].selected = true
                            } else {
                                this.quizPembahasan.subject.quests[index].soal.opsi[j].selected = false
                            }
                            if (element.soal.opsi[j].id == element.soal.kunci_jawaban) {
                                this.quizPembahasan.subject.quests[index].soal.opsi[j].correct = true
                            } else {
                                this.quizPembahasan.subject.quests[index].soal.opsi[j].correct = false
                            }
                        }
                    }
                });

          },

        },
        mounted() {

        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
