<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="items"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">
                                List Kelas
                            </h3>
                            <div class="card-tools">
                                <a
                                    href="javascript:void(0)"
                                    @click="
                                        $router.push('/manage/group/create')
                                    "
                                    class="btn btn-primary"
                                    v-if="$can('create-group')"
                                >
                                    <i class="fas fa-plus-circle"></i>
                                    Buat Kelas
                                </a>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="row pt-3 px-3">
                                <div class="col">
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 15%">ID Kelas</th>
                                            <th>Kelas</th>
                                            <th>Jumlah Siswa</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="group in groups.data"
                                            :key="group.id"
                                        >
                                            <td>{{ group.str_id }}</td>
                                            <td>{{ group.group_name }}</td>
                                            <td>{{ group.students_count }}</td>
                                            <td>
                                                <b-button
                                                    variant="success"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="
                                                        $router.push({
                                                            path: `/manage/group/edit/${group.id}`,
                                                        })
                                                    "
                                                    v-if="$can('edit-group') && group.deleted_at == null"
                                                >
                                                    <i class="fas fa-pen"></i>
                                                </b-button>
                                                <b-button
                                                    variant="primary"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="
                                                        $router.push({
                                                            path: `/manage/group/${group.id}`,
                                                        })
                                                    "
                                                    v-if="$can('detail-group') && group.deleted_at == null"
                                                >
                                                    <i class="fas fa-eye"></i>
                                                </b-button>
                                                <b-button
                                                    variant="danger"
                                                    size="sm"
                                                    class="mr-2"
                                                    @click="deleteGroup(group)"
                                                    v-if="$can('delete-group') && group.deleted_at == null"
                                                >
                                                    <i class="fas fa-trash"></i>
                                                </b-button>
                                                <b-button
                                                    variant="primary"
                                                    size="sm"
                                                    class="mr-2"
                                                    v-if="
                                                        $can('delete-group') && group.deleted_at != null
                                                    "
                                                    @click="
                                                        restoreGroup(group)
                                                    "
                                                >
                                                    <i class="fas fa-undo"></i>
                                                </b-button>
                                            </td>
                                        </tr>
                                        <tr v-if="isEmpty">
                                            <td colspan="4" align="center">
                                                Belum ada data
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-end mr-4">
                                <pagination
                                    :data="groups"
                                    @pagination-change-page="getResultsPaginate"
                                >
                                    <template #prev-nav>
                                        <span>Sebelumnya</span>
                                    </template>
                                    <template #next-nav>
                                        <span>Selanjutnya</span>
                                    </template>
                                </pagination>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import pagination from "laravel-vue-pagination";

export default {
    components: {
        pagination,
    },
    data() {
        return {
            groups: {
                data: {}
            },
            searchKeyword: "",
            totalTeacher: 0,
            items: [
                {
                    text: "Kelas",
                    active: false,
                },
            ],
        };
    },
    methods: {
        loadGroups() {
            this.$Progress.start();

            axios
                .get("/api/groups", {
                    params: {
                        search: this.searchKeyword,
                    },
                })
                .then(({ data }) => (this.groups = data.data));

            this.$Progress.finish();
        },
        getResultsPaginate(page = 1) {
            this.$Progress.start();

            axios
                .get("/api/groups", {
                    params: {
                        search: this.searchKeyword,
                        page: page,
                    },
                })
                .then(({ data }) => (this.groups = data.data));

            this.$Progress.finish();
        },
        deleteGroup(item) {
            this.$Progress.start();

            Swal.fire({
                title: "Hapus akun?",
                html: `Anda ingin menghapus kelas <b>${item.group_name}</b>. Kelas akan hilang dari sistem.`,
                showCancelButton: true,
                confirmButtonColor: "#e6e3e3",
                cancelButtonColor: "#d33",
                confirmButtonText: "Hapus",
                cancelButtonText: "Batal",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .delete("/api/groups/" + item.id)
                        .then((data) => {
                            if (data.data.success) {
                                Toast.fire({
                                    icon: "success",
                                    title: data.data.message,
                                });

                                this.$Progress.finish();

                                this.loadGroups();
                            } else {
                                Toast.fire({
                                    icon: "error",
                                    title: `${data.data.message}`,
                                });

                                this.$Progress.failed();
                            }
                        })
                        .catch((error) => {
                            if (error.response) {
                                if (error.response.data.errors) {
                                    let errors = error.response.data.errors;
                                    for (let i in errors) {
                                        Toast.fire({
                                            icon: "error",
                                            title: [...errors[i]],
                                        });
                                    }
                                } else if (error.response.data.success) {
                                    Toast.fire({
                                        icon: "error",
                                        title: error.response.data.message,
                                    });
                                } else {
                                    Toast.fire({
                                        icon: "error",
                                        title: error.response.data.message,
                                    });
                                }
                            } else if (error.request) {
                                console.log(2);
                            } else {
                                console.log(3);
                            }
                        });
                }
            });
        },
        restoreGroup(group) {
            this.$Progress.start();
            Swal.fire({
                title: "Restore kelas?",
                html: `Anda ingin mengembalikan kelas <b>${group.str_id}</b>?`,
                showCancelButton: true,
                confirmButtonColor: "#e6e3e3",
                cancelButtonColor: "#d33",
                confirmButtonText: "Restore",
                cancelButtonText: "Batal",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .put(`/api/group/${group.id}/restore`)
                        .then((data) => {
                            Toast.fire({
                                icon: "success",
                                title: data.data.message,
                            });
                            this.$Progress.finish();
                            this.loadGroups();
                        })
                        .catch((data) => {
                            console.log(data);
                        });
                }
            });
        }
    },
    mounted() {
        console.log("Component mounted ." + __dirname);
    },
    created() {
        this.loadGroups();
    },
    computed: {
        isEmpty(){
            return this.groups.data.length == 0 ? true : false
        },
    }
};
</script>
