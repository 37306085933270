<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                Aktivitas Siswa
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">
                                Aktivitas Siswa
                            </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <div
                                class="form-group has-search pull-left mt-5 ml-3 mb-3"
                                style="width: 250px"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari Siswa"
                                    @keyup="loadStudentActivity(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>ID Siswa</th>
                                        <th>Nama</th>
                                        <th>Kelas</th>
                                        <th>Aktivitas</th>
                                        <th>Nilai</th>
                                        <th>Tanggal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="activity in studentActivity.data"
                                        :key="activity.id"
                                    >
                                        <td>{{ activity.id_siswa }}</td>
                                        <td>{{ activity.nama }}</td>
                                        <td>{{ activity.kelas }}</td>
                                        <td>{{ activity.aktivitas }}</td>
                                        <td>{{ activity.nilai }}</td>
                                        <td>
                                            {{
                                                formatDateUtc(
                                                    activity.created_at
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->

                        <div class="card-footer">
                            <nav aria-label="..." class="float-right">
                                <pagination
                                    :data="studentActivity"
                                    @pagination-change-page="
                                        loadStudentActivity
                                    "
                                >
                                    <span slot="prev-nav">Sebelumnya</span>
                                    <span slot="next-nav">Selanjutnya </span>
                                </pagination>
                            </nav>
                        </div>
                        <!-- /.card-footer-->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            studentActivity: {},
            q: "",
        };
    },
    methods: {
        loadStudentActivity(page = 1) {
            this.$Progress.start();

            if (this.q) {
                axios
                    .get("/api/aktivitas/siswa?page=" + page + "&q=" + this.q)
                    .then(({ data }) => (this.studentActivity = data.data));
            } else {
                axios
                    .get("/api/aktivitas/siswa?page=" + page)
                    .then(({ data }) => (this.studentActivity = data.data));
            }

            this.$Progress.finish();
        },
        formatDateUtc(value) {
            if (value) {
                return moment(String(value)).format("DD MMMM YYYY, HH:mm:ss");
            }
        },
    },
    created() {
        this.$Progress.start();
        this.loadStudentActivity();
        this.$Progress.finish();
    },
};
</script>
