/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import { Form, HasError, AlertError } from "vform";
import Gate from "./Gate";
import Swal from "sweetalert2";
import VueProgressBar from "vue-progressbar";
import VueRouter from "vue-router";
import router from "./routes";
import VueRouterBackButton from "vue-router-back-button";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vue2Editor from "vue2-editor";
import Permissions from './Permissions';
import VueEllipseProgress from 'vue-ellipse-progress';

window.Vue = require("vue");

window.Form = Form;

Vue.prototype.$gate = new Gate(window.user);

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});
window.Swal = Swal;
window.Toast = Toast;

Vue.use(VueProgressBar, {
    color: "rgb(143, 255, 199)",
    failedColor: "red",
    height: "3px",
});

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component("font-awesome-icon", FontAwesomeIcon);

/**
 * Routes imports and assigning
 */
// Vue.use(VueRouter);
Vue.mixin(Permissions);

// const router = new VueRouter({
//     mode: "history",
//     routes,
// });
// Routes End

// Vue.use(VueRouterBackButton, { router });
Vue.use(BootstrapVue);
Vue.use(VueEllipseProgress);

//############################ START COMPONENTS ############################
Vue.component("pagination", require("laravel-vue-pagination"));

Vue.use(Vue2Editor);

Vue.component(
    "button-back",
    require("./components/build-in/ButtonBack.vue").default
);
// Vue.component('dashboard', require('./components/Dashboard.vue'));

// Vue.component(
//     'passport-clients',
//     require('./components/passport/Clients.vue').default
// );

// Vue.component(
//     'passport-authorized-clients',
//     require('./components/passport/AuthorizedClients.vue').default
// );

// Vue.component(
//     'passport-personal-access-tokens',
//     require('./components/passport/PersonalAccessTokens.vue').default
// );

// Vue.component(
//     'not-found',
//     require('./components/NotFound.vue').default
// );


// // Filter Section

// Vue.filter('myDate',function(created){
//     return moment(created).format('MMMM Do YYYY');
// });

// Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

// // end Filter

// Vue.component('example-component', require('./components/ExampleComponent.vue'));

//############################ END COMPONENTS ############################

const app = new Vue({
    el: "#app",
    router,
});
