<template>
    <section class="content">
        <div class="container-fluid">
            <template>
                <b-breadcrumb :items="items"></b-breadcrumb>
            </template>
            <div class="ml-3 mt-3 mb-3">
                <a href="/manage/quizzes"
                    ><i class="fa fa-angle-left" aria-hidden="true"> </i>
                    <span>Kembali</span>
                </a>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <div class="float-left mt-2">
                                <h3 class="card-title font-weight-bold pt-2">
                                    List Kuis - {{ subject.subjects_name }}
                                </h3>
                            </div>

                            <div class="card-tools">
                                <div class="float-right mt-1 mr-2">
                                    <a
                                        :href="`/manage/quizzes/${subject.str_id}/create`"
                                        class="btn btn-primary"
                                    >
                                        <i class="fa fa-plus-circle"></i>
                                        Buat Kuis
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <div
                                class="form-group has-search float-left mt-5 ml-3 mb-3"
                                style="width: 96%"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari berdasarkan judul..."
                                    @keyup="getResults(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Judul Kuis</th>
                                        <th>Jumlah Soal</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(quiz, index) in quizzes.data"
                                        :key="index"
                                    >
                                        <td>
                                            {{ index + quizzes.current_page }}
                                        </td>
                                        <td>{{ quiz.quiz_title }}</td>
                                        <td>{{ quiz.soal_count }}</td>
                                        <td>
                                            <a
                                                :href="`/manage/quizzes/${subject.str_id}/${quiz.id}/edit`"
                                                class="btn btn-sm btn-success mr-2"
                                            >
                                                <i class="fa fa-edit"></i>
                                            </a>

                                            <a
                                                :href="`/manage/quizzes/${subject.str_id}/${quiz.id}`"
                                                class="btn btn-sm btn-primary mr-2"
                                            >
                                                <i class="fa fa-eye"></i>
                                            </a>

                                            <button
                                                type="button"
                                                class="btn btn-sm btn-danger mr-2"
                                                @click="deleteQuiz(quiz.id)"
                                            >
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="quizzes"
                                @pagination-change-page="getResults"
                                class="d-flex justify-content-end mr-4"
                            >
                                <template #prev-nav>
                                    <span>Sebelumnya</span>
                                </template>
                                <template #next-nav>
                                    <span>Selanjutnya</span>
                                </template>
                            </pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import pagination from "laravel-vue-pagination";
import axios from "axios";

export default {
    components: {
        VueTagsInput,
        pagination,
    },
    data() {
        return {
            editmode: false,
            quizzes: {},
            subject: {},
            q: "",
            items: [
                {
                    text: "Kuis",
                    active: false,
                },
            ],
        };
    },
    methods: {
        loadSubject() {
            axios
                .get(`/api/subject/info/${this.$route.params.id}`)
                .then((data) => {
                    this.subject = data.data.data;
                    this.items.push({
                        text: this.subject.subjects_name,
                        active: true,
                    });
                });
        },
        getResults(page = 1) {
            this.$Progress.start();

            axios
                .get(
                    `/api/quiz/${this.$route.params.id}?q=${this.q}&page=${page}`
                )
                .then(({ data }) => (this.quizzes = data.data));

            this.$Progress.finish();
        },

        deleteQuiz(id) {
            Swal.fire({
                title: "Apakah kamu yakin?",
                text: "Data yang telah dihapus tidak dapat dikembalikan!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Ya, hapus saja!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .delete("/api/quiz/" + id)
                        .then(() => {
                            Swal.fire(
                                "Terhapus!",
                                "Kuis berhasil dihapus",
                                "success"
                            );
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Gagal!", data.message, "warning");
                        });
                }
            });
        },
    },
    created() {
        this.$Progress.start();

        this.loadSubject();

        this.getResults(1);

        this.$Progress.finish();
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
};
</script>
