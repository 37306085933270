var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumbs}})],_vm._v(" "),_c('ButtonBack'),_vm._v(" "),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header header-color text-white"},[_c('div',{staticClass:"float-left mt-2"},[_c('h3',{staticClass:"card-title font-weight-bold pt-2"},[_vm._v("\n                                "+_vm._s(_vm.subject.subjects_name)+"\n                            ")])])]),_vm._v(" "),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('div',{staticClass:"form-group has-search float-left mt-4 ml-3 mb-3",staticStyle:{"width":"96%"}},[_c('span',{staticClass:"fa fa-search form-control-feedback"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.q),expression:"q"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Cari berdasarkan judul..."},domProps:{"value":(_vm.q)},on:{"keyup":function($event){return _vm.getResults(1)},"input":function($event){if($event.target.composing){ return; }_vm.q=$event.target.value}}})]),_vm._v(" "),_c('table',{staticClass:"table table-hover"},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.quizzes.data),function(quiz,index){return _c('tr',{key:index},[_c('td',[_vm._v("\n                                        "+_vm._s(index +
                                            1 +
                                            (_vm.quizzes.current_page * 10 - 10))+"\n                                    ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(quiz.quiz_title))]),_vm._v(" "),_c('td',[_c('router-link',{attrs:{"to":{
                                                name: 'score-quiz-participant',
                                                params: {
                                                    quiz_id: quiz.id,
                                                    subject_id: _vm.subject.id,
                                                },
                                            }}},[_vm._v("\n                                            "+_vm._s("Lihat Detail")+"\n                                        ")])],1)])}),0)])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('pagination',{staticClass:"d-flex justify-content-end mr-4",attrs:{"data":_vm.quizzes},on:{"pagination-change-page":_vm.getResults},scopedSlots:_vm._u([{key:"prev-nav",fn:function(){return [_c('span',[_vm._v("Sebelumnya")])]},proxy:true},{key:"next-nav",fn:function(){return [_c('span',[_vm._v("Selanjutnya")])]},proxy:true}])})],1)])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_vm._v(" "),_c('th',[_vm._v("Judul Kuis")]),_vm._v(" "),_c('th',[_vm._v("Aksi")])])])}]

export { render, staticRenderFns }