<template>
  <section class="content">
    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a :href="`/manage/quizzes/${subject.str_id}`"><i class="fa fa-angle-left" aria-hidden="true"> </i> Kembali</a>
        </div>
        <div class="row">

          <div class="col-12">

            <div class="card w-75">
              <div class="card-header header-color">
                <div class="float-left mt-2">
                    <h3 class="card-title text-white">Data Kuis</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <b-form-group
                    label="Judul Quiz"
                    label-for="input"
                    id="input-group"
                >
                    <b-form-input
                        id="input"
                        v-model="quiz.quiz_title"
                        placeholder="Tulis judul quiz"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Waktu Pengerjaan"
                    label-for="input2"
                    id="input-group-2"
                    class="w-25"
                >
                <div class="grid-container">
                    <b-form-input
                        id="input2"
                        v-model="quiz.waktu_pengerjaan"
                        type="number"
                        readonly
                    />
                    <div class="grid-item align-middle" style="line-height: 25pt">
                        Menit
                    </div>
                </div>
                </b-form-group>

                <b-form-group
                    label="Pengaturan Soal"
                    label-for="input3"
                    id="input-group-3"
                >
                    <b-form-select
                        id="input-8"
                        v-model="quiz.is_random"
                        :options="question_options"
                        @change="buttonState"
                        required
                        disabled
                        class="w-25"
                        ></b-form-select>
                </b-form-group>

                <b-form-group
                    label="Jumlah Soal"
                    label-for="input-4"
                    id="input-group-4"
                    v-show="form.question_option == 1"
                >
                    <b-form-input
                        id="input-4"
                        type="number"
                        min="0"
                        class="w-25"
                        v-model="form.total_questions"
                    ></b-form-input>
                </b-form-group>
              </div>
              <!-- /.card-body -->
            </div>

            <div class="card w-75">
              <div class="card-header header-color">
                <div class="float-left mt-2">
                    <h3 class="card-title text-white">Soal Kuis</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <div style="padding-top: 20px; padding-left: 20px;">
                    <span>Pilih Soal</span>
                </div>

                <hr>

                <div style="padding-left: 20px;">
                    <strong>Bank Soal {{ subject.str_id }}</strong>
                </div>

                <hr>

                <div class="form-group has-search float-left mt-5 ml-3 mb-3" style="width:96%;">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control mb-2" placeholder="Cari soal.." @keyup="loadQuestion(1)" v-model="q">
                </div>

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Soal</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(key, index) in questions.data" :key="key.id">
                          <td>{{(index+1)+(currentPage*10-10)}}</td>
                          <td>{{strippedHtml(key.soal)}}</td>
                      </tr>
                  </tbody>
                </table>
                <div class="row">
                    <div class="col">
                        <nav aria-label="..." class="float-right" style="margin-right: 20px; margin-top: 20px;">
                            <pagination :data="questions" @pagination-change-page="loadQuestion">
                                <span slot="prev-nav">Sebelumnya</span>
                                <span slot="next-nav">Selanjutnya </span>
                            </pagination>
                        </nav>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span style="margin-left: 20px;">
                        Jumlah Soal dipilih: {{ questions.total }}
                        </span>
                    </div>
                </div>
                <div id="button-tools" class="text-center">
                    <a :href="`/manage/quizzes/${subject.str_id}`" class="btn btn-outline-secondary">Kembali</a>
                </div>
              </div>
              <!-- /.card-body -->
            </div>

            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import pagination from 'laravel-vue-pagination';
import axios from 'axios';

    export default {
      components: {
          VueTagsInput,
          pagination,
        },
        data () {
            return {
                form: {
                    title: "",
                    time: 0,
                    question_option: null,
                    questions: [],
                    total_questions: 0,
                },
                quiz : {},
                subject: {},
                q: '',
                questions: {},
                question_options: [
                    {
                        value: 0,
                        text: "Pilih Soal"
                    },
                    {
                        value: 1,
                        text: "Acak Soal"
                    },
                ],
                items: [
                {
                    text: 'Kuis',
                    active: false,
                }
              ],
            }
        },
        methods: {
          loadSubject() {
              axios.get(`/api/subject/info/${this.$route.params.id}`).then(( data ) => {
                this.subject = data.data.data
                this.items.push({
                    text: this.subject.subjects_name,
                    active: false,
                })
                this.items.push({
                    text: "Buat Kuis",
                    active: true
                })

            });
          },
          loadQuiz(){
            axios.get(`/api/quiz/show/${this.$route.params.quiz_id}`).then(( data ) => {
                this.quiz = data.data.data

                this.loadQuestion();

            });
          },
          loadQuestion(page=1){
              this.currentPage = page;
              this.$Progress.start();

              if(this.q){
                axios.get('/api/quiz/question/'+this.quiz.id+'?page=' + page+'&q='+this.q).then(({data}) => (this.questions = data.data));
              }else{
                axios.get('/api/quiz/question/'+this.quiz.id+'?page=' + page).then(({data}) => (this.questions = data.data));
              }

              this.$Progress.finish();
          },
            strippedHtml(text) {
                let regex = /(<([^>]+)>)/ig;

                return text.replace(regex, "");
            },
        },
        created() {
            this.$Progress.start();

            this.loadSubject();
            this.loadQuiz();

            this.$Progress.finish();
        }
    }
</script>

<style scoped>
#button-tools {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
}

.grid-container2 {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 48px;
}

.grid-item {
    text-align: center;
}
</style>
