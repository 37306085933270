<template>
  <section class="content">
    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a :href="`/manage/quizzes/${subject.str_id}`"><i class="fa fa-angle-left" aria-hidden="true"> </i> Kembali</a>
        </div>
        <div class="row">

          <div class="col-12">

            <div class="card w-75" v-show="state === 1">
              <div class="card-header header-color">
                <div class="float-left mt-2">
                    <h3 class="card-title text-white">Edit Kuis</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <b-form-group
                    label="Judul Quiz"
                    label-for="input"
                    id="input-group"
                >
                    <b-form-input
                        id="input"
                        v-model="form.title"
                        placeholder="Tulis judul quiz"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Waktu Pengerjaan"
                    label-for="input2"
                    id="input-group-2"
                    class="w-25"
                >
                <div class="grid-container">
                    <b-form-input
                        id="input2"
                        v-model="form.time"
                        type="number"
                    />
                    <div class="grid-item align-middle" style="line-height: 25pt">
                        Menit
                    </div>
                </div>
                </b-form-group>

                <b-form-group
                    label="Pengaturan Soal"
                    label-for="input3"
                    id="input-group-3"
                >
                    <b-form-select
                        id="input-8"
                        v-model="form.question_option"
                        :options="question_options"
                        @change="buttonState"
                        required
                        class="w-25"
                        ></b-form-select>
                </b-form-group>

                <b-form-group
                    label="Jumlah Soal"
                    label-for="input-4"
                    id="input-group-4"
                    v-show="form.question_option == 1"
                >
                    <b-form-input
                        id="input-4"
                        type="number"
                        min="0"
                        :max="subject.soal_count"
                        class="w-25"
                        v-model="form.total_questions"
                    ></b-form-input>
                </b-form-group>
                <div id="button-tools" class="grid-container2 w-50">
                    <button type="reset" class="btn btn-outline-secondary">Batal</button>
                    <button type="button" class="btn btn-primary" :class="btn_sub_prop.disabled ? 'disabled' : '' " :disabled="btn_sub_prop.disabled" @click="changeState">{{ btn_sub_prop.text }}</button>
                </div>
              </div>
              <!-- /.card-body -->
            </div>

            <div class="card w-75" v-show="state === 2">
              <div class="card-header header-color">
                <div class="float-left mt-2">
                    <h3 class="card-title text-white">Form Edit Kuis</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <div style="padding-top: 20px; padding-left: 20px;">
                    <span>Pilih Soal</span>
                </div>

                <hr>

                <div style="padding-left: 20px;">
                    <strong>Bank Soal {{ subject.str_id }}</strong>
                </div>

                <hr>

                <div class="form-group has-search float-left mt-5 ml-3 mb-3" style="width:96%;">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control mb-2" placeholder="Cari soal.." @keyup="loadQuestion(1)" v-model="q">
                </div>

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Soal</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(key, index) in questions.data" :key="key.id">
                          <td>{{(index+1)+(currentPage*10-10)}}</td>
                          <td v-html="key.soal"></td>
                          <td>
                            <b-form-checkbox :value="key.id" v-model="form.questions" :id="'quiz'+index"/>
                          </td>
                      </tr>
                  </tbody>
                </table>
                <div class="row">
                    <div class="col">
                        <nav aria-label="..." class="float-right" style="margin-right: 20px; margin-top: 20px;">
                            <pagination :data="questions" @pagination-change-page="loadQuestion">
                                <span slot="prev-nav">Sebelumnya</span>
                                <span slot="next-nav">Selanjutnya </span>
                            </pagination>
                        </nav>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span style="margin-left: 20px;">
                        Jumlah Soal dipilih: {{ form.questions.length }}
                        </span>
                    </div>
                </div>
                <div id="button-tools" class="grid-container2 w-50">
                    <button type="reset" class="btn btn-outline-secondary" @click="backState">Kembali</button>
                    <button type="button" class="btn btn-primary" @click="updateQuiz">Simpan Kuis</button>
                </div>
              </div>
              <!-- /.card-body -->
            </div>

            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import pagination from 'laravel-vue-pagination';
import axios from 'axios';

    export default {
      components: {
          VueTagsInput,
          pagination,
        },
        data () {
            return {
                editmode: false,
                state: 1,
                form: {
                    title: "",
                    time: 0,
                    question_option: null,
                    questions: [],
                    total_questions: 0,
                },
                quizzes : {},
                subject: {},
                q: '',
                questions: {},
                btn_sub_prop : {
                    text: "Simpan Kuis",
                    disabled: true,
                    state: 1,
                },
                question_options: [
                    {
                        value: null,
                        text: "Pilih Pengaturan"
                    },
                    {
                        value: 0,
                        text: "Pilih Soal"
                    },
                    {
                        value: 1,
                        text: "Acak Soal"
                    },
                ],
                items: [
                {
                    text: 'Kuis',
                    active: false,
                }
              ],
            }
        },
        methods: {
          loadSubject() {
              axios.get(`/api/subject/info/${this.$route.params.id}`).then(( data ) => {
                this.subject = data.data.data
                this.items.push({
                    text: this.subject.subjects_name,
                    active: false,
                })
                this.items.push({
                    text: "Buat Kuis",
                    active: true
                })

            });
          },
          loadQuiz(){
            axios.get(`/api/quiz/show/${this.$route.params.quiz_id}`).then(( data ) => {
                this.form = {
                    title: data.data.data.quiz_title,
                    time: data.data.data.waktu_pengerjaan,
                    question_option: data.data.data.is_random,
                    questions: [],
                    total_questions: data.data.data.soal_count,
                }

                this.buttonState(this.form.question_option)

                this.loadQuizQuestion();

            });
          },
          loadQuizQuestion(){
            axios.get(`/api/quiz/question-ids/${this.$route.params.quiz_id}`).then(( data ) => {
                this.form.questions = data.data.data.question_ids
            });
          },
          loadQuestion(page=1){
              this.currentPage = page;
              this.$Progress.start();

              if(this.q){
                axios.get('/api/questions/'+this.subject.id+'?page=' + page+'&q='+this.q).then(({data}) => (this.questions = data.data));
              }else{
                axios.get('/api/questions/'+this.subject.id+'?page=' + page).then(({data}) => (this.questions = data.data));
              }

              this.$Progress.finish();
          },
          changeState(){
            if (this.form.question_option == 0) {
                this.state=2
                this.loadQuestion()
            } else {
                this.updateQuiz()
            }
          },
          backState(){
            this.state=1
          },
          buttonState(e) {
            console.log(e)
                if (e === 0) {
                    this.btn_sub_prop.text="Selanjutnya"
                    this.btn_sub_prop.state=2
                    this.btn_sub_prop.disabled=false
                } else if (e === 1) {
                    this.btn_sub_prop.text="Simpan Kuis"
                    this.btn_sub_prop.state=1
                    this.btn_sub_prop.disabled=false
                } else {
                    this.btn_sub_prop.text="Simpan Kuis"
                    this.btn_sub_prop.state=1
                    this.btn_sub_prop.disabled=true
                }
            },
            strippedHtml(text) {
                let regex = /(<([^>]+)>)/ig;

                return text.replace(regex, "");
            },
            updateQuiz(){
              this.$Progress.start();

              axios.put(`/api/quiz/${this.$route.params.quiz_id}`,{
                subject_id: this.subject.id,
                quiz_title: this.form.title,
                time: this.form.time,
                is_random: this.form.question_option,
                question_ids: this.form.questions,
                total_questions: this.form.total_questions,
              })
              .then((data)=>{
                if(data.data.success){

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();

                  this.$router.push(`/manage/quizzes/${this.subject.str_id}`)

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
        },
        created() {
            this.$Progress.start();

            this.loadSubject();
            this.loadQuiz();

            this.$Progress.finish();
        }
    }
</script>

<style scoped>
#button-tools {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
}

.grid-container2 {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 48px;
}

.grid-item {
    text-align: center;
}
</style>
