<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h4 class="">Akun / Lihat Akun</h4>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <button-back />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">Lihat Akun</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <h4 v-if="type == 'student'">
                                        Paket Bimbel
                                    </h4>
                                    <h4 v-if="type == 'teacher'">Tipe Akun</h4>
                                </div>
                                <div class="col-md-5">
                                    <div
                                        class="form-group"
                                        v-if="type == 'student'"
                                    >
                                        <label for="classSelect">Kelas</label>
                                        <input
                                            v-model="detail.group_name"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            readonly
                                        />
                                    </div>
                                    <div
                                        class="form-group"
                                        v-if="type == 'teacher'"
                                    >
                                        <label for="classSelect"
                                            >Mata Pelajaran</label
                                        >
                                        <input
                                            v-model="detail.subject_name"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <h4>Profil</h4>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label
                                            for="name"
                                            v-if="type == 'student'"
                                            >Nama Siswa</label
                                        >
                                        <label
                                            for="name"
                                            v-if="type == 'teacher'"
                                            >Nama Pengajar</label
                                        >
                                        <input
                                            v-model="detail.name"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Status</label>
                                        <input
                                            v-model="detail.status"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="id" v-if="type == 'student'"
                                            >ID Siswa</label
                                        >
                                        <label for="id" v-if="type == 'teacher'"
                                            >ID Pengajar</label
                                        >
                                        <input
                                            v-model="detail.str_id"
                                            type="text"
                                            class="form-control"
                                            id="id"
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <input
                                            v-model="detail.password"
                                            type="password"
                                            class="form-control"
                                            id="password"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" v-if="type == 'student'">
                                <div class="col-md-12">
                                    <h4>Rentang Waktu Belajar</h4>
                                </div>
                            </div>
                            <div class="row" v-if="type == 'student'">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="date-start"
                                            >Tanggal Masuk</label
                                        >
                                        <input
                                            v-model="detail.start_date"
                                            type="date"
                                            class="form-control"
                                            id="date-start"
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="date-end"
                                            >Tanggal Selesai</label
                                        >
                                        <input
                                            v-model="detail.end_date"
                                            type="date"
                                            class="form-control"
                                            id="date-end"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" v-if="type == 'student'">
                                <div class="col-md-12">
                                    <h4>Rincian Pembelajaran</h4>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="type == 'student'">
                                <div class="col-md-5">
                                    <label for="date-end">Mata Pelajaran</label>
                                    <div
                                        v-for="option in detail.subjects_list"
                                        :key="option"
                                        class="row"
                                    >
                                        <div class="col mb-1">
                                            <b-form-input
                                                :value="option"
                                                readonly
                                            ></b-form-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="type == 'student'">
                                <div class="col-md-5">
                                    <label for="total_quiz">Jumlah Kuis</label>
                                    <b-form-input
                                        id="total_quiz"
                                        v-model="detail.total_quiz"
                                        value="0/48"
                                        readonly
                                    ></b-form-input>
                                </div>
                                <div class="col-md-5">
                                    <label for="total_tryout"
                                        >Jumlah Try Out</label
                                    >
                                    <b-form-input
                                        id="total_tryout"
                                        v-model="detail.total_tryout"
                                        value="0/48"
                                        readonly
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            detail: {
                group_name: "",
                name: "",
                status: "",
                str_id: "",
                password: "",
                start_date: "",
                end_date: "",
                subjects_list: [],
                total_quiz: "",
                total_tryout: "",
            },
            type: "",
        };
    },
    methods: {
        getDataDetail(id, type) {
            axios
                .get(`/api/account/${type}/${id}`)
                .then(({ data }) => (this.detail = data.data));
        },
    },
    computed: {
        back() {
            if (
                this.client ||
                !this.$routerHistory ||
                !this.$routerHistory.hasPrevious()
            ) {
                // probably ssr, or hasn't navigated yet.
                return { path: "/" };
            }
            return { path: this.$routerHistory.previous().path };
        },
    },
    created() {
        this.getDataDetail(this.$route.params.id, this.$route.query.type);
        this.type = this.$route.query.type;
    },
};
</script>
