<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="items"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <button-back />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">
                                Form Edit Kelas
                            </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <form @submit.prevent="editGroup">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">ID Kelas</label>
                                            <input
                                                v-model="form.str_id"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                placeholder="Tulis ID Kelas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name">Kelas</label>
                                            <input
                                                v-model="form.group_name"
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                placeholder="Nama Kelas"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label for="id">Kode Kelas</label>
                                            <input
                                                v-model="form.prefix_code"
                                                type="text"
                                                class="form-control"
                                                id="id"
                                                placeholder="Contoh: Akpol (AKP)"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name"
                                                >Mata Pelajaran</label
                                            ><br />
                                            <small
                                                >Ceklist mata pelajaran yang
                                                akan disajikan dalam
                                                kelas.</small
                                            >
                                            <div class="border rounded-sm p-3">
                                                <div
                                                    v-for="(
                                                        subject, index
                                                    ) in options.subjects"
                                                    :key="index"
                                                >
                                                    <div
                                                        class="custom-control custom-checkbox"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            :value="subject.id"
                                                            v-model="selected"
                                                            :id="subject.id"
                                                            @change="
                                                                getTextSelected
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            :for="subject.id"
                                                            >{{
                                                                subject.subjects_name
                                                            }}</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name"
                                                >Mata Pelajaran</label
                                            ><br />
                                            <span
                                                v-for="(
                                                    sel, index
                                                ) in selectedList"
                                                :key="index"
                                                >{{ sel }}<br
                                            /></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5 px-5">
                                    <b-col class="text-center">
                                        <b-button
                                            variant="outline-secondary"
                                            size="lg"
                                            class="px-5"
                                            :to="back"
                                            >Batal</b-button
                                        >
                                        <b-button
                                            variant="primary"
                                            size="lg"
                                            class="px-5"
                                            type="submit"
                                            >Simpan</b-button
                                        >
                                    </b-col>
                                </div>
                            </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: new Form({
                str_id: "",
                group_name: "",
                prefix_code: "",
                subjects: [],
            }),
            selected: [],
            selectedList: [],
            options: {
                subjects: [],
            },
            items: [
                {
                    text: "Kelas",
                    active: true,
                    href: "manage/groups",
                },
                {
                    text: "Buat Kelas",
                    active: false,
                },
            ],
        };
    },
    methods: {
        getDataDetail(id) {
            this.$Progress.start();

            axios.get(`/api/groups/${id}`).then(({ data }) => {
                this.form.id = data.data.id;
                this.form.str_id = data.data.str_id;
                this.form.group_name = data.data.group_name;
                this.form.prefix_code = data.data.prefix_code;

                data.data.subjects.forEach((e) => {
                    this.selected.push(e.id);
                });
            });

            this.$Progress.finish();
        },
        loadSubjects(id) {
            axios.get("/api/subject/list").then(({ data }) => {
                this.options.subjects = data.data;

                this.selectedList = [];
                let vm = this;
                let data2 = this.options.subjects.filter(function (el) {
                    return vm.selected.includes(el.id);
                });
                let data3 = data2.forEach((e) => {
                    vm.selectedList.push(e.subjects_name);
                });
            });
        },
        editGroup() {
            this.form.subjects = this.selected;

            this.$Progress.start();
            this.form
                .put(`/api/groups/${this.form.id}`)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.$Progress.finish();

                        this.$router.push({
                            path: `/manage/groups`,
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: `${data.data.message}`,
                        });

                        this.$Progress.failed();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.errors) {
                            let errors = error.response.data.errors;
                            for (let i in errors) {
                                Toast.fire({
                                    icon: "error",
                                    title: [...errors[i]],
                                });
                            }
                        } else if (error.response.data.success) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.message,
                            });
                        }
                    } else if (error.request) {
                        console.log(2);
                    } else {
                        console.log(3);
                    }
                });
        },
        getTextSelected() {
            this.selectedList = [];
            let vm = this;
            let data = this.options.subjects.filter(function (el) {
                return vm.selected.includes(el.id);
            });
            let data2 = data.forEach((e) => {
                vm.selectedList.push(e.subjects_name);
            });
        },
    },
    computed: {
        back() {
            if (
                this.client ||
                !this.$routerHistory ||
                !this.$routerHistory.hasPrevious()
            ) {
                // probably ssr, or hasn't navigated yet.
                return { path: "/" };
            }
            return { path: this.$routerHistory.previous().path };
        },
    },
    created() {
        this.getDataDetail(this.$route.params.id);
        this.loadSubjects();
    },
};
</script>
