<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <template>
                        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
                    </template>
                </div>
            </div>

            <div class="pt-3">
                <h3>Informasi Paket Bimbel</h3>
                <h4 class="mt-3">Profil Siswa</h4>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="name">Nama Siswa</label>
                        <input
                            v-model="detail.name"
                            type="text"
                            class="form-control"
                            id="name"
                            readonly
                        />
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="id">ID Siswa</label>
                        <input
                            v-model="detail.str_id"
                            type="text"
                            class="form-control"
                            id="id"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Paket Bimbel</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="group_name">Kelas</label>
                        <input
                            v-model="detail.group_name"
                            type="text"
                            class="form-control"
                            id="group_name"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Rentang Waktu Belajar</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="date_start">Tanggal Masuk</label>
                        <input
                            v-model="detail.start_date"
                            type="date"
                            class="form-control"
                            id="date_start"
                            readonly
                        />
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="date_end">Tanggal Selesai</label>
                        <input
                            v-model="detail.end_date"
                            type="date"
                            class="form-control"
                            id="date_end"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Rincian Pembelajaran</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="date_end">Mata Pelajaran</label>
                        <div
                            class="row"
                            v-for="subject in detail.subjects_list"
                            :key="subject"
                        >
                            <div class="col mb-1">
                                {{ subject }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="total_quiz">Jumlah Kuis</label>
                        <input
                            v-model="progress_quiz.final"
                            type="text"
                            class="form-control"
                            id="total_quiz"
                            readonly
                        />
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="total_tryout">Jumlah Try Out</label>
                        <input
                            v-model="progress_tryout.final"
                            type="text"
                            class="form-control"
                            id="total_tryout"
                            readonly
                        />
                    </div>
                </div>
            </div>
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbs: [
                {
                    text: "Paket Bimbel",
                    active: false,
                },
            ],
            detail: {},
            subjects_list: [],
            subjects_list_id: [],
            total_quiz: "",
            progress_current_quiz: 0,
            progress_quiz: {
                current: 0,
                total: 0,
                final: "",
            },
            progress_tryout: {
                current: 0,
                total: 0,
                final: "",
            },
        };
    },
    methods: {
        getDetail(id) {
            axios.get(`/api/account/student/${id}`).then(({ data }) => {
                this.detail = data.data;
                this.loadGroupSubjects(this.detail.group_id);
            });
        },
        async loadGroupSubjects(id) {
            await axios.get(`/api/group/subject/${id}`).then(({ data }) => {
                this.subjects_list = data.data[0];
                this.subjects_list_id = data.data[1];
            });
        },
        async loadProgress() {
            await axios.get(`/api/dashboard/student`).then(({ data }) => {
                this.progress_quiz.final = data.data.card.progress_quiz
                this.progress_tryout.final = data.data.card.progress_tryout
            });
        },
    },
    created() {
        this.getDetail(window.user.id);
        this.loadProgress();
    }
};
</script>
