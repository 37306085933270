<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h4 class="">Selamat datang, {{ authName }}</h4>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"
                            ><i class="fas fa-user"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Total Siswa</span>
                            <span class="info-box-number">
                                {{ statistics.students }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"
                            ><i class="fas fa-graduation-cap"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Jumlah Kelas</span>
                            <span class="info-box-number">
                                {{ statistics.classes }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"
                            ><i class="fas fa-file-alt"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Bank Soal</span>
                            <span class="info-box-number">
                                {{ statistics.quiz_banks }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header bg-primary">
                            <h3 class="card-title p-0 m-0">Aktivitas Siswa</h3>
                            <div class="card-tools">
                                <span
                                    ><a
                                        href="#"
                                        @click="
                                            $router.push('/student-activities')
                                        "
                                        class="text-white"
                                        >Lihat semua aktivitas</a
                                    ></span
                                >
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0">
                                    <thead>
                                        <tr>
                                            <th>ID Siswa</th>
                                            <th>Nama</th>
                                            <th>Kelas</th>
                                            <th>Aktivitas</th>
                                            <th>Nilai</th>
                                            <th>Tanggal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="activity in activities.data"
                                            :key="activity.id"
                                        >
                                            <td>
                                                {{ activity.id }}
                                            </td>
                                            <td>{{ activity.nama }}</td>
                                            <td>
                                                <span
                                                    class="badge badge-success"
                                                >
                                                    {{ activity.kelas }}
                                                </span>
                                            </td>
                                            <td>{{ activity.aktivitas }}</td>
                                            <td>{{ activity.nilai }}</td>
                                            <td>
                                                {{
                                                    formatDateUtc(
                                                        activity.created_at
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header bg-primary">
                            <h3 class="card-title p-0 m-0">Soal</h3>
                            <div class="card-tools">
                                <span
                                    ><a
                                        href="#"
                                        @click="$router.push('/questions')"
                                        class="text-white"
                                        >Lihat semua soal</a
                                    ></span
                                >
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0">
                                    <thead>
                                        <tr>
                                            <th>ID Mapel</th>
                                            <th>Mata Pelajaran (MAPEL)</th>
                                            <th>Jumlah Soal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="course in courses.data"
                                            :key="course.str_id"
                                        >
                                            <td>{{ course.str_id }}</td>
                                            <td>
                                                {{
                                                    course.subjects_name.substring(
                                                        0,
                                                        60
                                                    )
                                                }}
                                            </td>
                                            <td>{{ course.soal_count }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            authName: document
                .querySelector("meta[name='user-name']")
                .getAttribute("content"),
            statistics: {
                students: 0,
                classes: 0,
                quiz_banks: 0,
            },
            activities: {},
            courses: {},
        };
    },
    methods: {
        loadTotalStudents() {
            axios
                .get("/api/student/count")
                .then(({ data }) => (this.statistics.students = data.data));
        },
        loadTotalGroups() {
            axios
                .get("/api/group/count")
                .then(({ data }) => (this.statistics.classes = data.data));
        },
        loadTotalSoal() {
            axios
                .get("/api/questions/count")
                .then(({ data }) => (this.statistics.quiz_banks = data.data));
        },
        loadStudentActivities() {
            axios
                .get("/api/aktivitas/siswa?page=1")
                .then(({ data }) => (this.activities = data.data));
        },
        loadCourse(page = 1) {
            axios
                .get("/api/question-courses?page=" + page)
                .then(({ data }) => (this.courses = data.data));
        },
        formatDateUtc(value) {
            if (value) {
                return moment(String(value)).format("DD MMMM YYYY, HH:mm:ss");
            }
        },
    },
    created() {
        this.loadTotalStudents();
        this.loadTotalGroups();
        this.loadTotalSoal();
        this.loadCourse();
        this.loadStudentActivities();
    },
};
</script>
