<template>
  <section class="content w-100 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3><b>{{ quiz.subject.subjects_name }} - {{ quiz.quiz_title }}</b></h3>
          <p>Durasi : {{ quiz.waktu_pengerjaan }} Menit</p>
          <p>Jumlah : {{ quiz.soal_count }} Soal</p>
        </div>
      </div>
      <div class="row">
        <img :src="bgImg" style="max-width:100%;"/>
      </div>
      <div class="row">
        <div class="col-12 text-center">
            <a :href="`/siswa/quiz/${routeID}/mengerjakan/${quiz.id}`" class="btn btn-primary">Mulai Test</a>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>

</style>
<script>
import axios from 'axios';
import Countdown from '../countdown/Countdown';

    export default {
      components: {
        Countdown
      },
        data () {
            return {
                quiz: {},
                bgImg: '',
                routeID: "",
            }
        },
        methods: {
            load(){

                axios
                    .get("/api/siswa/quiz-detail/"+this.$route.params.quiz_id)
                    .then(({ data }) => (this.quiz = data.data));

                this.$Progress.finish();

                let host = location.host;
                let loc ='';
                if(!host.includes("http://") && !host.includes("https://")){
                    loc = 'http://'+host
                }
                this.routeID = this.$route.params.id

                this.bgImg = loc+'/storage/images/Petunjuk Pengerjaan Soal.png';
            }
        },
        created() {
            this.$Progress.start();
            this.load()
            this.$Progress.finish();
        }
    }
</script>
