import { render, staticRenderFns } from "./DetailQuiz.vue?vue&type=template&id=70ae684e&scoped=true&"
import script from "./DetailQuiz.vue?vue&type=script&lang=js&"
export * from "./DetailQuiz.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ae684e",
  null
  
)

export default component.exports