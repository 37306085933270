<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="items"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8 pt-3">
                    <!-- MAP & BOX PANE -->
                    <button-back />
                    <div class="card mt-4">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">Lihat Kelas</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-3 px-3">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="id">ID Kelas</label>
                                        <input
                                            v-model="form.str_id"
                                            type="text"
                                            class="form-control"
                                            id="id"
                                            placeholder="Tulis ID Kelas"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name">Kelas</label>
                                        <input
                                            v-model="form.group_name"
                                            type="text"
                                            class="form-control"
                                            id="name"
                                            placeholder="Nama Kelas"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="id">Kode Kelas</label>
                                        <input
                                            v-model="form.prefix_code"
                                            type="text"
                                            class="form-control"
                                            id="id"
                                            placeholder="Contoh: Akpol (AKP)"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name">Mata Pelajaran</label
                                        ><br />
                                        <small
                                            >Ceklist mata pelajaran yang akan
                                            disajikan dalam kelas.</small
                                        >
                                        <div class="border rounded-sm p-3">
                                            <div
                                                v-for="subject in selected"
                                                :key="subject.id"
                                            >
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        :value="{
                                                            id: subject.id,
                                                            text: subject.subjects_name,
                                                        }"
                                                        :id="subject.id"
                                                        checked="checked"
                                                        onclick="return false;"
                                                    />
                                                    <label
                                                        class="custom-control-label"
                                                        :for="subject.id"
                                                        >{{
                                                            subject.subjects_name
                                                        }}</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: new Form({
                str_id: "",
                group_name: "",
                prefix_code: "",
                subjects: [],
            }),
            selected: [],
            options: {
                subjects: [],
            },
            items: [
                {
                    text: "Kelas",
                    active: true,
                    href: "manage/groups",
                },
                {
                    text: "Lihat Kelas",
                    active: false,
                },
            ],
        };
    },
    methods: {
        getDataDetail(id) {
            axios.get(`/api/groups/${id}`).then(({ data }) => {
                // this.options.subjects = data.data
                this.form.str_id = data.data.str_id;
                this.form.group_name = data.data.group_name;
                this.form.prefix_code = data.data.prefix_code;
                this.selected = data.data.subjects;
            });
        },
        loadSubject() {
            axios
                .get("/api/subject/list")
                .then(({ data }) => (this.options.subjects = data.data));
        },
    },
    computed: {
        back() {
            if (
                this.client ||
                !this.$routerHistory ||
                !this.$routerHistory.hasPrevious()
            ) {
                // probably ssr, or hasn't navigated yet.
                return { path: "/" };
            }
            return { path: this.$routerHistory.previous().path };
        },
    },
    created() {
        this.getDataDetail(this.$route.params.id);
        this.loadSubject();
    },
};
</script>
