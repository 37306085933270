<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <template>
                        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
                    </template>
                </div>
            </div>
            <!-- /.row -->

            <div class="row col-8">
                <!-- Left col -->
                <!-- <div class="col-8"> -->
                    <!-- MAP & BOX PANE -->
                    <!-- <div class="row"> -->
                        <router-link :to="{ name: 'list-score-tryouts' }" class="col-lg-3 bg-primary text-white p-4 text-center h6 font-weight-bold text-decoration-none mr-2">
                            {{ "Try Out" }}
                        </router-link>
                        <router-link :to="{ name: 'list-score-quizzes' }" class="col-lg-3 bg-success text-white p-4 text-center h6 font-weight-bold text-decoration-none">
                            {{ "Kuis" }}
                        </router-link>
                    <!-- </div> -->
                    <!-- /.card -->
                    <!-- /.card -->
                <!-- </div> -->
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbs: [
                {
                    text: "Nilai",
                    active: false,
                },
            ],
        };
    },
    methods: {},
};
</script>
