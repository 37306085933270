<template>
    <section class="content">
        <div class="container-fluid">
            <template>
                <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
            </template>
            <ButtonBack />
            <div class="row mt-3">
                <div class="col-8">
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <div class="float-left mt-2">
                                <h3 class="card-title font-weight-bold pt-2">
                                    {{ subject.subjects_name }}
                                </h3>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <div
                                class="form-group has-search float-left mt-4 ml-3 mb-3"
                                style="width: 96%"
                            >
                                <span
                                    class="fa fa-search form-control-feedback"
                                ></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cari berdasarkan judul..."
                                    @keyup="getResults(1)"
                                    v-model="q"
                                />
                            </div>

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Judul Kuis</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(quiz, index) in quizzes.data"
                                        :key="index"
                                    >
                                        <td>
                                            {{
                                                index +
                                                1 +
                                                (quizzes.current_page * 10 - 10)
                                            }}
                                        </td>
                                        <td>{{ quiz.quiz_title }}</td>
                                        <td>
                                            <router-link
                                                :to="{
                                                    name: 'score-quiz-participant',
                                                    params: {
                                                        quiz_id: quiz.id,
                                                        subject_id: subject.id,
                                                    },
                                                }"
                                            >
                                                {{ "Lihat Detail" }}
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="quizzes"
                                @pagination-change-page="getResults"
                                class="d-flex justify-content-end mr-4"
                            >
                                <template #prev-nav>
                                    <span>Sebelumnya</span>
                                </template>
                                <template #next-nav>
                                    <span>Selanjutnya</span>
                                </template>
                            </pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import pagination from "laravel-vue-pagination";
import axios from "axios";
import ButtonBack from "../../build-in/ButtonBack";

export default {
    components: {
        ButtonBack,
        VueTagsInput,
        pagination,
    },
    data() {
        return {
            editmode: false,
            quizzes: {},
            subject: {},
            q: "",
            breadcrumbs: [
                {
                    text: "Nilai",
                    active: false,
                    to: { name: 'list-score' },
                },
                {
                    text: "Kuis",
                    active: false,
                    to: { name: 'list-score-quizzes'},
                },
            ],
        };
    },
    methods: {
        loadSubject() {
            axios
                .get(`/api/subject/info/${this.$route.params.id}`)
                .then((data) => {
                    this.subject = data.data.data;
                    this.breadcrumbs.push({
                        text: this.subject.subjects_name,
                        active: true,
                    });
                });
        },
        getResults(page = 1) {
            this.$Progress.start();

            axios
                .get(
                    `/api/quiz/${this.$route.params.id}?q=${this.q}&page=${page}`
                )
                .then(({ data }) => (this.quizzes = data.data));

            this.$Progress.finish();
        },

        deleteQuiz(id) {
            Swal.fire({
                title: "Apakah kamu yakin?",
                text: "Data yang telah dihapus tidak dapat dikembalikan!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Ya, hapus saja!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .delete("/api/quiz/" + id)
                        .then(() => {
                            Swal.fire(
                                "Terhapus!",
                                "Kuis berhasil dihapus",
                                "success"
                            );
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Gagal!", data.message, "warning");
                        });
                }
            });
        },
    },
    created() {
        this.$Progress.start();

        this.loadSubject();

        this.getResults(1);

        this.$Progress.finish();
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
};
</script>
