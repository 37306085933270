<template>
  <section class="content w-75">

    <div class="container-fluid">
        <template>
            <b-breadcrumb :items="items"></b-breadcrumb>
        </template>
        <div class="ml-3 mt-3 mb-3">
            <a href="/manage/try-out"><i class="fa fa-arrow-left" aria-hidden="true"> </i> Kembali</a>
        </div>

        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header bg-info">

                <div class="float-left mt-2">
                    <h3 class="card-title">Lihat Try Out</h3>
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                
                    <template>
                    <div class="mt-4 mb-2 ml-3 mr-3">
                        <b-form @submit="onSubmit" @reset="onReset" v-if="show">

                            <b-form-group id="input-group-1" label="Kelas*" label-for="input-1">
                                <b-form-select
                                id="input-1"
                                v-model="form.kelas"
                                :options="optionsKelas"
                                required
                                class="w-25"
                                disabled
                                ></b-form-select>
                            </b-form-group>

                            <b-form-group
                                label="Judul Try Out*"
                                label-for="input-2"
                                id="input-group-2"
                            >
                                <b-form-input
                                id="input-2"
                                v-model="form.judul_tryout"
                                placeholder="Tulis judul try out"
                                disabled
                                class="w-100"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                label="Tanggal*"
                                label-for="input-3"
                                id="input-group-3"
                            >
                              <input type="datetime-local" v-model="form.date" id="input-3" disabled class="form-control w-50" />
                            </b-form-group>

                            <b-form-group
                                label="Waktu Pengerjaan*"
                                label-for="input-4"
                                id="input-group-4"
                            >
                                <div class="form-inline">
                                  <b-form-input
                                  id="input-4"
                                  type="number"
                                  v-model="form.time"
                                  disabled
                                  class="w-25 mr-2"
                                  min="0"
                                  ></b-form-input> <span>Menit</span>
                                </div>
                            </b-form-group>

           
                            <table class="table table-borderless" style="width:65%;" v-if="showMapel">
                            <thead>
                                <tr>
                                <th scope="col" class="col-3">Mata Pelajaran</th>
                                <th scope="col" class="col-3">Jumlah Soal*</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(data, index) in classMapel" :key="data.id">
                                    <td class="col-3">{{data.subjects_name}}</td>
                                    <td lass="col-3">
                                        <b-form-input
                                        :id="'input-mapel-'+index"
                                        type="number"
                                        :value="data.pivot.jumlah_soal"
                                        disabled
                                        class="w-50"
                                        required
                                        ></b-form-input>
                                    </td>
                                </tr>

                            </tbody>
                            </table>       
                               
                            <div class="container">
                            <div class="row">
                                <div class="col text-center pt-4 pb-4">
                                    <a href="/manage/try-out"  class="btn btn-lg btn-outline-dark btn-light">Kembali</a>
                                </div>
                            </div>
                            </div>

                        </b-form>

                    </div>
                    </template>
        

   
              
              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <nav aria-label="..." class="float-right">
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
        </div>


    </div>
  </section>
</template>

<script>
    
    import axios from 'axios';

    export default {
        data () {
            return {
              q: '',
              subjectType : null,
              items: [
                    {
                        text: 'Try Out',
                        href: '/manage/try-out'
                    },
                    {
                        text: 'Buat Try Out',
                        active: true
                    }
                ],
                selectedKelas: null,
                optionsKelas: [],
                optionsKelasType : [],
                classMapel: [],
                form: {
                    kelas: null,
                    judul_tryout: '',
                    date: null,
                    time: 0,
                    mataPelajaran: {},

                },
                show: true,
                showMapel: false,
            }
        },
        methods: {  
            initialData(){
                axios.get('/api/tryout/view/'+this.$route.params.id).then(({data}) => {
                    this.optionsKelas.push({value: null, text: data.kelas.group_name}); 
                    this.form.judul_tryout = data.judul
                    this.form.date = data.tanggal
                    this.form.time = data.waktu_pengerjaan
                    this.classMapel = data.subjects
                    this.showMapel = true

                });
            },
            setKelas(){

            },

            onSubmit(event) {
                event.preventDefault()
            },
            onReset(event) {

            }

          
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.initialData();
            this.$Progress.finish();
        }
    }
</script>
