<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                Pengajar
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-8">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header header-color text-white">
                            <h3 class="card-title font-weight-bold pt-2">List Pengajar</h3>
                            <div class="card-tools pt-2 pr-3">
                                <span>Total Pengajar : {{totalTeacher}}</span>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="row pt-3 px-3">
                                <div class="col">
                                    <form
                                        @submit.prevent="searchForm()"
                                        class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <button
                                                type="submit"
                                                class="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            name="search"
                                            class="form-control"
                                            v-model="searchKeyword"
                                            placeholder="Cari berdasarkan ID"
                                        />
                                    </form>
                                </div>
                                <div class="col">
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                        <th style="width: 15%">ID</th>
                                        <th>Nama</th>
                                        <th>Mata Pelajaran</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="teacher in teachers.data" :key="teacher.id">
                                            <td>{{teacher.str_id}}</td>
                                            <td>{{teacher.name}}</td>
                                            <td>{{teacher.subject_name}}</td>
                                        </tr>
                                        <tr v-if="!teachers.data.length">
                                            <td colspan="3" align="center">Belum ada data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-end mr-4">
                                <pagination :data="teachers" @pagination-change-page="getResultsPaginate">
                                    <template #prev-nav>
                                        <span>Sebelumnya</span>
                                    </template>
                                    <template #next-nav>
                                        <span>Selanjutnya</span>
                                    </template>
                                </pagination>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!--/. container-fluid -->
    </section>
</template>

<script>
import pagination from 'laravel-vue-pagination';

export default {
    components: {
        pagination,
    },
    data() {
        return {
            teachers: {},
            searchKeyword: '',
            totalTeacher: 0
        };
    },
    methods: {
        loadTeachers() {
            axios.get("/api/teachers", {
                params: {
                    search: this.searchKeyword
                }
            }).then(({ data }) => (this.teachers = data.data));
        },
        loadTotalTeachers() {
            axios.get("/api/teacher/count").then(({ data }) => (this.totalTeacher = data.data));
        },
        getResultsPaginate(page = 1) {

            this.$Progress.start();

            axios.get('/api/teachers', {
                params: {
                    search: this.searchKeyword,
                    page: page
                }
            }).then(({ data }) => (this.teachers = data.data));

            this.$Progress.finish();
        },
        searchForm() {
            this.loadTeachers()
        }
    },
    mounted() {
        console.log("Component mounted ." + __dirname);
    },
    created() {
        this.loadTeachers()
        this.loadTotalTeachers()
    },
};
</script>
